import React, { useEffect, useState } from 'react';
import network from '../../imgs/entrepreneurship.gif';
import answer from '../../imgs/network.gif';
import star from '../../imgs/AIstart.svg';
import chat from '../../imgs/chat.gif';
import interview from '../../imgs/online-interview.gif';
import vlogo from '../../imgs/verandaLog.png';
import ContentLoader, { Code } from 'react-content-loader'
import Typewriter from 'typewriter-effect';
import ai from '../../imgs/Group 200.png';
import assessment from '../../imgs/ai.gif';
import img1 from '../../imgs/img1.jpg';
import img2 from '../../imgs/img2.jpg';
import img3 from '../../imgs/img3.png';
import img4 from '../../imgs/img4.png';
import img5 from '../../imgs/img5.jpg';
import axios from 'axios';
import { apiURL, wssURL } from '../../CommonConfig/BaseUrl';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';



function KnowledgeScreen() {
  // Store the JSON data in a constant
  const jsonData = [
    {
      "question": "Where is the Eiffel Tower located, and when was it built",
      "answer": {
        "response": "The Eiffel Tower is located in Paris, France. It was constructed in 1889 and stands 324 meters tall.",
        "references": ["History of the Eiffel Tower", "France Tourism Board"],
        "page_number": [1, 2],
        "file_paths": ["/docs/eiffel_tower/history.pdf"],
        "file_contents": ["The Eiffel Tower was designed by Gustave Eiffel and has become one of the most iconic structures in the world."]
      }
    },
    {
      "question": "How can I create a REST API in FastAPI",
      "answer": {
        "response": "To create a REST API in FastAPI, you need to install FastAPI and Uvicorn, define your API endpoints, and run the application using the Uvicorn server.",
        "references": ["FastAPI Documentation", "REST API Guide"],
        "page_number": [4, 5],
        "file_paths": ["/docs/fastapi_guide.pdf", "/docs/rest_api_basics.txt"],
        "file_contents": [
          "FastAPI is a modern, fast web framework for building APIs with Python 3.6+ based on standard Python type hints.",
          "A REST API defines a set of functions that developers can perform requests and receive responses via HTTP protocol."
        ]
      }
    },
    {
      "question": "What is the formula to solve a quadratic equation",
      "answer": {
        "response": "To solve a quadratic equation, use the formula: x = (-b ± √(b² - 4ac)) / 2a.",
        "references": ["Algebra Textbook", "Khan Academy Math"],
        "sender_id": "math_student_001",
        "page_number": [8, 12],
        "file_paths": ["/docs/quadratic_equations.pdf"],
        "file_contents": [
          "The quadratic formula is derived from the process of completing the square and provides solutions for any quadratic equation in the form ax² + bx + c = 0."
        ]
      }
    },
    {
      "question": "Can you summarize the main machine learning techniques discussed in the document",
      "answer": {
        "response": "The document discusses various machine learning techniques such as supervised and unsupervised learning, neural networks, and support vector machines.",
        "references": ["Machine Learning 101", "Supervised Learning Overview"],
        "sender_id": "researcher007",
        "page_number": [2, 7, 15],
        "file_paths": ["/docs/ml_basics.pdf", "/notes/ai_overview.txt"],
        "file_contents": [
          "Supervised learning involves training a model on labeled data to make predictions.",
          "Unsupervised learning is used for analyzing data without pre-existing labels."
        ]
      }
    },
    {
      "question": "What does this Python function do",
      "answer": {
        "response": "The function takes two arguments, a and b, and returns their sum. It is written in Python.",
        "references": ["Python Documentation - Functions"],
        "sender_id": "dev_user89",
        "page_number": [3],
        "file_paths": ["/docs/python_basics.pdf"],
        "file_contents": [
          "def add(a, b): return a + b"
        ]
      }
    }
  ];
  const MyLoader = () => <ContentLoader />;
  const MyCodeLoader = () => <Code />;

  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [currentData, setCurrentData] = useState({ response: "", file_paths: [], page_number: [], references: [], file_contents: [] });
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isInfoBoxVisible, setIsInfoBoxVisible] = useState(false);
  const [isSideVisible, setIsSideVisible] = useState(false);
  const [isHelpSectionVisible, setIsHelpSectionVisible] = useState(true);
  const [isactive, setIsactive] = useState(false);
  const [assigneddata, setAssigneddata] = useState([]);
  const [assignedHubdata, setAssignedHubdata] = useState([]);
  const [assignedHubId, setAssignedHubId] = useState(1);
  const navigate = useNavigate();
  const [showDescription, setShowDescription] = useState(false);
  const [genieAnswer, setGenieAnswer] = useState("");
  const [selectedHubId, setSelectedHubId] = useState(0);

  console.log(assigneddata, "assigneddata")



  const handleClear = () => {
    setIsInfoBoxVisible(false);
    setIsSideVisible(false);
    setUserInput('');
    setIsactive(false)
    setCurrentData(null);
    setIsHelpSectionVisible(true);
    setShowDescription(false);
  };
  // useEffect(() => {
  //   console.log("Updated currentData Response:", currentData.response);
  //   console.log("Updated currentData FilePath:", currentData.file_paths);
  //   console.log("Updated currentData Page Number:", currentData.page_number);
  //   console.log("Updated currentData References:", currentData.references);
  //   console.log("Updated currentData File Contents:", currentData.file_contents);
  // }, [currentData]);

  const redirectToPage = (query, response) => {
    navigate(`/AssessmentMcq?query=${encodeURIComponent(query)}&response=${encodeURIComponent(response)}`);
  };


  const handleSubmit = () => {
    setIsactive(true);
    setIsVisible(true);
    setLoading(true);
    setIsInfoBoxVisible(true);
    setIsSideVisible(false);
    setIsHelpSectionVisible(false);
    let ws;
    function connect() {
      // ws = new WebSocket('wss://wealthgbtbot.moolaah.com:8765');
      ws = new WebSocket(wssURL);
      // ws = new WebSocket('ws://localhost:8765');
      ws.onopen = function () {
        console.log("WebSocket connection opened.");
        if (userInput) {
          ws.send(JSON.stringify({ senderId: 101, message: userInput, hub_id: selectedHubId }));
        }
        // environment in 1
      };
      ws.onmessage = function (event) {
        try {
          // 
          // file_paths
          // : 
          // ["data/1/data/Environment_UPSC.pdf", "Unknown"]
          // page_number
          // : 
          // ["32"]
          // references
          // : 
          // []
          // response
          // : 
          // "The edge effect in ecology refers to the increase in species diversity and abundance along the boundary between two ecosystems."
          // sender_id
          // : 
          // 101
          const parsedData = JSON.parse(event.data);
          setShowDescription(false);
          setGenieAnswer("");
          setCurrentData((prevData) => ({
            ...prevData,
            response: parsedData.response || "",
            file_paths: parsedData.file_paths || "",
            page_number: parsedData.page_number || "",
            references: parsedData.references || "",
            file_contents: parsedData.file_contents || "",
          }));
          setLoading(false)

        } catch (error) {
          console.error("Failed to parse response as JSON:", error);
        }
      };
      ws.onerror = function (error) {
        console.error("WebSocket Error: ", error);
      };
      ws.onclose = function () {
        console.log("WebSocket connection closed.");
        setTimeout(connect, 5000);
      };
    }
    connect();
  };

  const handleLogoutClick = () => {
    Swal.fire({
      title: 'Do you want to log out?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        localStorage.clear();
        navigate('/');
      }
    });
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && userInput.trim()) {
      setMessages((prevMessages) => [...prevMessages, userInput.trim()]);
      console.log('hellooooooooooooo', messages)

      setIsactive(true);
      setIsVisible(true);
      setLoading(true);
      setShowDescription(false);
      setGenieAnswer("");
      setIsInfoBoxVisible(true);
      setIsSideVisible(false);
      setIsHelpSectionVisible(false);
      let ws;
      function connect() {
        // debugger;
        // ws = new WebSocket('wss://wealthgbtbot.moolaah.com:8765');
        ws = new WebSocket(wssURL);
        // ws = new WebSocket('ws://localhost:8765');
        ws.onopen = function () {
          console.log("WebSocket connection opened.");
          if (userInput) {
            ws.send(JSON.stringify({ senderId: 101, message: userInput, hub_id: selectedHubId }));
          }
          // world history in 2
        };
        ws.onmessage = function (event) {
          try {
            const parsedData = JSON.parse(event.data);
            setCurrentData((prevData) => ({
              ...prevData,
              response: parsedData.response || "",
              file_paths: parsedData.file_paths || "",
              page_number: parsedData.page_number || "",
              references: parsedData.references || "",
              file_contents: parsedData.file_contents || "",
            }));
            setLoading(false)

          } catch (error) {
            console.error("Failed to parse response as JSON:", error);
          }
        };
        ws.onerror = function (error) {
          console.error("WebSocket Error: ", error);
        };
        ws.onclose = function () {
          console.log("WebSocket connection closed.");
          setTimeout(connect, 5000);
        };
      }
      connect();
    }
  };

  const openPdfPopup = (pdfPath, pageNumber) => {
    console.log(pdfPath);

    // Create a new window or popup
    const pdfWindow = window.open('', '_blank', 'width=800,height=600');

    // Create an iframe to embed the PDF viewer
    const iframe = document.createElement('iframe');
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';

    // Embed the PDF with the page number reference
    // You can use a query parameter for the page number or just pass it directly to a PDF viewer URL if you have one
    // iframe.src = `https://synviz-bucket.blr1.digitaloceanspaces.com/synviz-bucket/Knowledge_Hub/data/dummy.pdf`;  // Assuming the path can handle page numbers in the query string
    // iframe.src = `https://devmoolaah.s3.ap-south-1.amazonaws.com/emp_form/Moolaah_IFD_Empanelment_Form_piTuhXX_1.pdf#page=4`;  // Assuming the path can handle page numbers in the query string
    // iframe.src = `https://devmoolaah.s3.ap-south-1.amazonaws.com/bot-data/data/1/data/NCERT-Class-9-Geography-1.pdf#page=${pageNumber}`;  // Assuming the path can handle page numbers in the query string
    //iframe.src = `https://devmoolaah.s3.ap-south-1.amazonaws.com/bot-data/${pdfPath}#page=${pageNumber}`;  // Assuming the path can handle page numbers in the query string
    iframe.src = `https://devmoolaah.s3.ap-south-1.amazonaws.com/bot-data/${pdfPath.replace(/^.*?\/data/, "data")}#page=${pageNumber}`;
    // iframe.src = `https://devmoolaah.s3.ap-south-1.amazonaws.com/bot-data/data/1/data/Enviornment_UPSC.pdf#page=${pageNumber}`

    // Append the iframe to the popup window
    pdfWindow.document.body.appendChild(iframe);
  };



  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (!dataFetched) {
      getData();
      getHubData(sessionStorage.getItem('id'));
      setDataFetched(true);
      // openPdfPopup("paramOne", "paramTwo")
    }
  }, [dataFetched]);

  const getData = async () => {
    axios.get(`${apiURL}/get_assessment_team_data/${sessionStorage.getItem('id')}`)
      .then((response) => {
        if (response.data) {
          setAssigneddata(response.data);
          console.log(response.data)
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }

  const getHubData = async (student_id) => {
    try {
      const response = await axios.get(`${apiURL}/get_knowledge_team_data/${student_id}`);
      if (response.data) {
        const hubForUser = response.data.knowledge_data;
        setAssignedHubdata(hubForUser);
        if (hubForUser.length > 0) {
          setAssignedHubId(hubForUser[0].id);
        }
        console.log("Assigned Hub Data:", hubForUser);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const toggleDescriptionFromGenie = async (question, answer) => {
    try {
      setGenieAnswer('')
      const encodedQuestion = encodeURIComponent(question);
      const encodedAnswer = encodeURIComponent(answer);
      const response = await axios.post(`${apiURL}/genie?question=${encodedQuestion}&answer=${encodedAnswer}`);
      if (response && response.data) {
        console.log("Response data:", response.data);
        const genieData = response.data.explanation;
        setGenieAnswer(genieData);
        setShowDescription(true);
      } else {
        console.error("No data received:", response);
      }
    } catch (error) {
      // Handle the error in a user-friendly way
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <style>{`
        body {
        background-color: #2b2d42;
        color: #edf2f4;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }

      /* Navbar styling */
      .navbar {
        background-color: #2ea87e !important;
        border-bottom: 1px solid rgba(154, 140, 152, 0.3);
        z-index: 1000;
        position: fixed;
        width: 100%;
        top: 0;
        height: 67px;

      }

      /* Sidebar styles */
      .sidebar {
        background: rgba(42, 42, 46, 0.7);
        backdrop-filter: blur(10px);
        padding-top: 20px;
        box-shadow: 2px 0 10px rgba(0, 0, 0, 0.5);
        position: fixed; /* Fixed position */
        top: 70px; /* Space from the top (considering the navbar height) */
        bottom: 0;
        left: 0;
        width: 250px;
        transition: all 0.3s ease-in-out;
        z-index: 999; /* Ensure it's above other elements */
      }

      .sidebar a {
        color: #b0a8b9;
        padding: 15px 20px;
        display: block;
        font-size: 16px !important;
        border-radius: 8px;
        transition: background 0.2s;
        font-size: 14px !important;
      }

      .sidebar a:hover {
        background-color: rgba(74, 78, 105, 0.7);
        color: #edf2f4;
      }

      .sidebar h4 {
        color: #edf2f4;
        margin-bottom: 20px;
        text-align: center;
      }

      /* Content styles */
      .content {
        margin-left: 270px; /* Space for the fixed sidebar */
        padding: 20px;
        padding-top: 90px; /* Space for the fixed navbar */
      }

      .card {
        background: rgba(58, 59, 60, 0.7);
        backdrop-filter: blur(10px);
        border: none;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
        border-radius: 12px;
      }

      .card-header {
        background: rgba(74, 78, 105, 0.7);
        color: #edf2f4;
        font-weight: bold;
      }

      /* Input section styles */
      .input-section {
        background: rgba(74, 78, 105, 0.7);
        backdrop-filter: blur(10px);
        padding: 15px;
        border-radius: 12px;
        margin-bottom: 15px;
        display: flex;
      }

      .input-field {
        width: 100%;
        border: 1px solid #2ea87e;
        border-radius: 25px;
        padding: 10px 20px;
        transition: border-color 0.3s;
        background: rgba(52, 58, 64, 0.7);
        color: #edf2f4;
      }

      .input-field:focus {
        border-color: #007bff;
        outline: none;
      }

      /* Chat box styles */
      .chat-box {
        background: rgba(52, 58, 64, 0.7);
        border-radius: 12px;
        padding: 20px;
        max-height: 65vh;
        overflow-y: auto;
      }

      .chat-box p {
        padding: 8px 15px;
        background: rgba(74, 78, 105, 0.7);
        border-radius: 15px;
        color: #edf2f4;
        margin-bottom: 10px;
        width: fit-content;
        max-width: 80%;
      }

      .chat-box .user {
        background-color: rgba(108, 117, 125, 0.7);
        align-self: flex-end;
      }

      /* Scrollbar customization */
      .chat-box::-webkit-scrollbar {
        width: 8px;
      }

      .chat-box::-webkit-scrollbar-thumb {
        background-color: rgba(74, 78, 105, 0.7);
        border-radius: 10px;
      }

      .chat-box::-webkit-scrollbar-thumb:hover {
        background-color: rgba(154, 140, 152, 0.7);
      }

      /* Button styles */
      .btn {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.3s, transform 0.3s;
      }

      .btn:hover {
        background-color: rgba(0, 123, 255, 0.7);
        transform: scale(1.1);
      }

      .animate-icon {
        transition: transform 0.2s;
      }

      .animate-icon:hover {
        transform: rotate(20deg);
      }

      /* Add a glowing effect for AI theme */
      .btn-primary {
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
      }

      .btn-secondary {
        box-shadow: 0 0 5px rgba(108, 117, 125, 0.5);
      }

      .ai-overview-section {
        display: flex;
        align-items: center;
        margin-top: 10px;
        cursor: pointer;
      }

      .ai-overview-icon {
        width: 30px;
        height: 30px;
      }

      /* Heartbeat animation */
      .heartbeat-animation {
        animation: heartbeat 1.5s ease-in-out infinite;
      }

      /* Keyframes for heartbeat effect */
      @keyframes heartbeat {
        0% {
          transform: scale(1);
        }
        25% {
          transform: scale(1.1);
        }
        50% {
          transform: scale(1);
        }
        75% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }

      body,
      html {
        scroll-behavior: smooth;
      }

      .info-box {
        background-color: unset;
        border-radius: 10px;
        padding: 20px;
        max-width: 100%;
        margin: 20px auto;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
      }

      .title {
        font-size: 1.3rem;
        margin-bottom: 20px;
        color: #ffffff;
      }

      .sources {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin-bottom: 20px;
      }

      .source-item {
        background-color: #333333;
        padding: 10px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        width: calc(28.22% - 10px);
        box-sizing: border-box;
        color: #b3b3b3;
        border: 1px solid #2ea87e;
      }

      .source-item a {
        color: #7cc5ff;
        text-decoration: none;
        margin-bottom: 5px;
      }

      .source-name {
        font-size: 0.8rem;
        color: #999999;
      }

      .view-more {
        color: #7cc5ff;
        text-decoration: none;
        margin-top: 10px;
        display: block;
      }

      .description {
        background-color: #333333;
        padding: 15px;
        border-radius: 8px;
      }

      .description-title {
        color: #ffffff;
        font-size: 1.2rem;
        margin-bottom: 10px;
      }

      /* Icon and Text Container Styling */
      .icon-text {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        color: #b3b3b3;
        font-size: 1rem;
      }

      /* Icon Styling */
      .icon-text i {
        font-size: 1.5rem;
        color: #7cc5ff;
      }

      /* Description Styling */
      .description {
        background-color: #333333;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 20px;
      }

      .description-title {
        color: #ffffff;
        font-size: 1.2rem;
        margin-bottom: 10px;
      }

      .collapse-btn {
        background: none;
        border: none;
        color: #7cc5ff;
        cursor: pointer;
      }

      /* New Thread Button */
      .new-thread {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #333333;
        color: #ffffff;
        border: none;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        margin-bottom: 20px;
        font-size: 1rem;
      }

      .shortcut {
        background-color: #555555;
        padding: 2px 5px;
        border-radius: 4px;
        font-size: 0.8rem;
        color: #e0e0e0;
      }

      /* Navigation Menu */
      .nav-menu {
        display: flex;
        flex-direction: column;
      }

      .nav-item {
        display: flex;
        align-items: center;
        color: #b3b3b3;
        text-decoration: none;
        font-size: 1rem;
        padding: 10px;
        border-radius: 8px;
        transition: background 0.3s;
      }

      .nav-item i {
        font-size: 1.2rem;
      }

      .nav-item:hover {
        background-color: #333333;
        color: #ffffff;
      }

      .active {
        background-color: #333333;
        color: #ffffff;
      }

      /* Auth Section */
      .auth-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: center;
      }

      .signup-btn {
        background-color: #f5f5f5;
        color: #333333;
        border: none;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 1rem;
      }

      .login-link {
        color: #7cc5ff;
        text-decoration: none;
        font-size: 0.9rem;
      }

      .sidebar {
        width: 250px;
        background-color: #1c1c1c;
        color: #b3b3b3;
        padding: 15px;
        min-height: 100vh;
      }

      /* Navigation Menu Styles */
      .nav-menu {
        display: flex;
        flex-direction: column;
      }

      .nav-item {
        color: #b3b3b3;
        text-decoration: none;
        padding: 10px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: color 0.3s;
      }

      .nav-item.active,
      .nav-item:hover {
        color: #ffffff;
        border: 1px solid #2ea87e;
      }

      /* Dropdown Menu */
      .dropdown-menu {
        display: none; /* Hidden by default */
        flex-direction: column;
        background-color: #333333;
        margin-left: 20px; /* Indent to differentiate from main items */
        padding: 10px;
        border-radius: 8px;
      }

      .dropdown-item {
        color: #b3b3b3;
        text-decoration: none;
        padding: 8px 0;
        transition: color 0.3s;
      }

      .dropdown-item:hover {
        color: #ffffff;
      }

      .description {
        position: relative;
        margin-bottom: 20px;
      }

      .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .description-title {
        margin: 0;
      }

      .icon-container {
        display: flex;
        gap: 10px;
      }

      .icon-container i {
        cursor: pointer;
        font-size: 18px;
        color: #555;
        transition: color 0.3s;
      }

      .icon-container i:hover {
        color: #007bff; /* Change color on hover */
      }

      .like-icon {
        color: #28a745; /* Green color for like */
      }

      .dislike-icon {
        color: #dc3545; /* Red color for dislike */
      }

      .copy-icon {
        color: #6c757d; /* Gray color for copy */
      }

        .accordion-item {
          margin-bottom: 0px;
          border: 0;
          background-color: transparent;
   
}
         .sources {
    /* Add some spacing if necessary */
    margin: 20px;
}

.source-item {
    margin-bottom: 15px;
    position: relative; /* Needed for tooltip positioning */
}

.tooltip-link {
    position: relative; /* Position relative for the tooltip */
    text-decoration: none; /* Remove underline from link */
    color: #007bff; /* Link color */
    font-weight: normal;
}
    .tooltip-linkcolor {
    position: relative; /* Position relative for the tooltip */
    text-decoration: none; /* Remove underline from link */
    color: red; /* Link color */
    font-weight: bold;
}



.tooltip-link:hover .tooltip {
    visibility: visible;
    opacity: 1; /* Show tooltip on hover */
}

.tooltip-content {
    color: #ffcc00; /* Set the color you want for content */
    font-weight: normal; /* Optional: make it bold */
    font-size: 16px;
}

.tooltip {
    visibility: hidden;
    width: 250px; /* Adjust width as needed */
    background-color: #333; /* Dark background */
    color: #fff; /* White text */
    text-align: center;
    border-radius: 8px; /* Slightly rounded corners */
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: 250%; /* Position above the link */
    left: 50%;
    transform: translateX(-50%); /* Center the tooltip */
    opacity: 0; /* Start hidden */
    transition: opacity 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

.tooltip-link:hover .tooltip {
    visibility: visible;
    opacity: 1; /* Show tooltip on hover */
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  flex: 1;
  min-width: 200px;
  text-align: center;
}

.card-content h3 {
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.card-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.card-content ul li {
  margin-bottom: 4px;
  font-size: 0.9rem;
}

.small-card {
  flex: 0 0 calc(33.333% - 16px);
  padding: 12px;
  text-align: center;
}



.image-card img,
.full-img {
  width: 40%;
  height: auto;
  border-radius: 8px;
}

.watch-card {
  position: relative;
  text-align: center;
  padding: 12px;
}

.watch-img {
  width: 40%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 8px;
}

.watch-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.watch-button:hover {
  background-color: #0056b3;
}

.image-row-row {
  display: flex;
  gap: 5px; /* Space between images */
  margin-bottom: -80px;
}

.image-container {
  text-align: center;
}

.small-card-img {
  width: 100%; /* Adjust the width to make the images smaller */
  height: 50%;
  border-radius: 5px;
}
  .overlay-text {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 10px;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;

}

.image-container {
  position: relative;
  display: inline-block;
}
  .big-image-container {
  text-align: center; /* centers the image horizontally */
  margin-bottom: 20px; /* adds some space below the large image */
}
  .big-card-img {
  width: 100%; /* makes the image responsive */
  max-width: 600px; /* ensures it doesn't get too large */
  height: 200px; /* maintains aspect ratio */
   border-radius: 5px;
}
   .accordion-collapse {
  max-height: 400px;  /* Set the maximum height for the content */
  overflow-y: auto;   /* Enable vertical scrolling if the content exceeds the max height */
}

.big-image-container {
  text-align: center;
  margin-bottom: 20px;
}

.image-row-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
}

.image-container {
  flex: 1;
  max-width: 48%;
  position: relative; /* Relative positioning for the icon overlay */
  text-align: center;
}

.small-card-img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Optional: Adds rounded corners to the images */
}

.overlay-text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px;
  border-radius: 5px;
}

.icon-overlay {
  position: absolute;
  top: 10px; /* Adjust for positioning */
  right: 10px; /* Adjust for positioning */
  font-size: 16px; /* Size of the icon */
  color: white; /* Color of the icon */
  background: rgba(0, 0, 0, 0.5); /* Background for visibility */
  border-radius: 50%; /* Circular background */
  padding: 5px; /* Padding for the icon background */
}


.ai-logo-img {
  width: 24px; /* Adjust the size of the AI logo */
  height: 24px; /* Adjust the size of the AI logo */
  cursor: pointer; /* Optional: pointer cursor for interactivity */
}

.help-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  // background-color: #1c1c1c; /* Dark background */
  color: #ffffff; /* Light text color */
  padding: 2rem;
  border-radius: 8px;
}

.help-title {
  font-size: 30px !important; 
  margin-bottom: 1rem;
  color:#ffff;
}

.button-container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;
}


.help-button {
  background-color: #333333; /* Button color */
  color: #ffffff;
  border: none;
  padding: 0.7rem 1.2rem;
  font-size: 16px;
  font-weight: 500;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.help-button i {
  font-size: 18px; /* Icon size */
}

.help-button:hover {
  background-color: #555555; /* Lighter color on hover */
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.help-button:active {
  transform: translateY(0);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}


.disclaimer {
  font-size: 14px;
  color: #aaaaaa; /* Lighter gray text for disclaimer */
}
 
.sidebar h2{
margin-bottom: 0px !important;
}




/* Button Styling */
.assessment-button {
  background: #2EA87E; 
  border: none;
  border-radius: 25px;
  padding: 10px 9px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  position: relative;
  overflow: hidden;
}

/* Hover effect */
.assessment-button:hover {
  background: #1d8a61; /* Darker shade for hover effect */
  transform: translateY(-3px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

/* Focus effect */
.assessment-button:focus {
  outline: none;
  box-shadow: 0px 0px 10px rgba(46, 168, 126, 0.7);
}

/* Dark Pattern Effect on Hover */
.assessment-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.4s ease;
}

.assessment-button:hover::after {
  transform: translate(-50%, -50%) scale(1);
}


            `}</style>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <img
              src={vlogo}
              style={{ width: "100px", marginLeft: "10px" }}
              alt="Navbar Icon"
              className="rounded-circle"
            />
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <h4 className="ms-auto mx-2">
            <span>Hi, </span>{sessionStorage.getItem('username')}
          </h4>
          <i
            className="fa fa-sign-out mb-2"
            aria-hidden="true"
            style={{ fontSize: 'x-large', cursor: 'pointer' }}
            onClick={handleLogoutClick}
            title="Log Out"
          ></i>
        </div>
      </nav>

      <div className="d-flex">
        {/* Sidebar */}
        <div className="sidebar">
          <div class="accordion accordion-primary accordion-with-icon" id="accordion-one">
            <div class="accordion-item">
              <h2 class="accordion-header accordion-header-primary" id="headingOne6">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne10" aria-expanded="false">
                  <span class="fas fa-clipboard"></span>
                  <span class="accordion-header-text "> Assessment Hubs</span>
                </button>
              </h2>
              <div id="collapseOne10" className="accordion-collapse collapse" aria-labelledby="headingOne6" data-bs-parent="#accordion-six1">
                <div className="" id="discover-dropdown">
                  {assigneddata.length > 0 ? (
                    assigneddata.map((assigneddatas, index) => (
                      <a
                        key={assigneddatas.id}
                        href={`/InterviewBot?id=${assigneddatas.id}`}
                        className="dropdown-item"
                      >
                        {`${index + 1}. ${assigneddatas.bot_name}`}
                      </a>
                    ))
                  ) : (
                    <p className="text-muted">No bots assigned.</p>
                  )}
                </div>
              </div>

            </div>
            <div className="accordion-item">
              <h2 className="accordion-header accordion-header-primary mt-3" id="headingOne7">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne11"
                  aria-expanded="false"
                >
                  <span className="fas fa-clipboard"></span>
                  <span className="accordion-header-text"> Knowledge Hubs</span>
                </button>
              </h2>
              <div id="collapseOne11" className="accordion-collapse collapse" aria-labelledby="headingOne7" data-bs-parent="#accordion-six">
                <div id="discover-dropdown">
                  {assignedHubdata.length > 0 ? (
                    assignedHubdata.map((hub, index) => (
                      <a key={hub.id} href={`/KnowledgeScreen?id=${hub.id}`} className="dropdown-item"
                        onClick={() => {
                          sessionStorage.setItem('hub_id', hub.id);
                          localStorage.setItem('hub_id', hub.id);
                          setSelectedHubId(hub.id);
                        }}
                      >
                        {`${index + 1}. ${hub.bot_name}`}
                      </a>
                    ))
                  ) : (
                    <p className="text-muted">No assigned hubs found.</p>
                  )}
                </div>
              </div>
            </div>

            <div className="">
              <a className="nav-item active mt-4" id="home-btn">
                <img
                  src={chat}
                  style={{ width: "30px", marginRight: "8px" }}
                  alt="Icon"
                  className="rounded-circle"
                /> Chat History
              </a>
              <div className="">
                {isVisible && (
                  <div className="messages mt-2">
                    {messages.map((message, index) => (
                      <p key={index} className="message">
                        {message}
                      </p>
                    ))}
                  </div>
                )}
              </div>

            </div>

            <div className="accordion-item mt-4">
              <a className="nav-item d-flex align-items-center" id="discover-btn">
                <img
                  src={interview}
                  style={{ width: "30px", marginRight: "8px" }}
                  alt="Icon"
                  className="rounded-circle"
                />
                Assessment History
              </a>
              <div className="dropdown-menu" id="discover-dropdown">
                <a className="dropdown-item">Explore 1</a>
                <a className="dropdown-item">Explore 2</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        {isactive && (
          <div className='row'>

            <div className=" col-9 d-flex align-items-center">

              <div className="input-section d-flex align-items-center flex-grow-1">
                <input
                  type="text"
                  className="input-field form-control"
                  placeholder="Type your message here..."
                  id="messageInput"
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <button className="btn btn-primary mx-3" onClick={handleSubmit}>
                  <i className="fas fa-paper-plane animate-icon" title="Submit"></i>
                </button>
                <button
                  className="btn btn-secondary mx-2"
                  id="clearBtn"
                  onClick={handleClear}
                >
                  <i className="fas fa-trash animate-icon" title="Clear"></i>
                </button>
              </div>
            </div>
            <div className='col-2' style={{ marginLeft: '70px' }}>
              <a href='/InterviewBot' className="btn mx-2 mb-2" id="botBtn">
                <img
                  src={assessment}
                  style={{ width: "45px", marginRight: "8px" }}
                  alt="Icon"
                  className="rounded-circle"
                />
              </a>


              <Typewriter
                options={{
                  loop: true, // Loop indefinitely
                }}
                onInit={(typewriter) => {
                  typewriter.typeString('<span style="margin-left: -45px; font-size: 18px;">Self </span>')
                    .typeString('<span style="font-size: 18px;">Assessment</span>')
                    .pauseFor(500)
                    .typeString(' 💼')
                    .pauseFor(500)
                    .deleteChars(2)
                    .typeString(' 📚')
                    .pauseFor(500)
                    .deleteChars(2)
                    .typeString(' 💡')
                    .pauseFor(500)
                    .deleteChars(2)
                    .pauseFor(500)
                    .start();
                }}
              />

            </div>

          </div>
        )}


        <div className='row'>
          <div className="col-10 ai-overview-section d-flex align-items-center mt-3 mb-3">
            <img
              src={star}
              alt="AI Icon"
              className="ai-overview-icon mr-2 heartbeat-animation"
            />
            <a className="ai-overview-link">AI Overview</a>
          </div>

        </div>
        {isHelpSectionVisible && (
          <div className="help-section">
            <h2 className="help-title">
              <Typewriter
                options={{
                  loop: true, // The typing effect will loop indefinitely
                }}
                onInit={(typewriter) => {
                  typewriter.typeString('How can I be of assitance today?') // Type out the text
                    .pauseFor(2500) // Pause for 2.5 seconds
                    .start(); // Start the typing effect
                }}
              />
            </h2>
            <div className=" col-9 d-flex align-items-center ">
              {/* Input Section */}
              <div className="input-section d-flex align-items-center flex-grow-1">
                <input
                  type="text"
                  className="input-field form-control"
                  placeholder="Type your message here..."
                  id="messageInput"
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <button className="btn btn-primary mx-3" onClick={handleSubmit}>
                  <i className="fas fa-paper-plane animate-icon" title="Submit"></i>
                </button>
                <button
                  className="btn btn-secondary mx-2"
                  id="clearBtn"
                  onClick={handleClear} // Handle Clear button click
                >
                  <i className="fas fa-trash animate-icon" title="Clear"></i>
                </button>
              </div>
            </div>
            <p className="disclaimer">Learning is the only thing the mind never exhausts, never fears, and never regrets</p>
          </div>
        )}

        {/* Response Section */}
        <div className='row'>
          {isInfoBoxVisible && ( // Conditionally render the info-box
            <div className=" col-xl-9 info-box">
              {currentData && (
                loading ? (
                  <MyCodeLoader />
                  // Alternatively, you can use <MyFacebookLoader /> here if you prefer
                ) : (
                  <>
                    <h4 className="title">{userInput}</h4>

                    <div className="icon-text">
                      <img
                        src={network}
                        style={{ width: "30px" }}
                        alt="Icon"
                        className="rounded-circle"
                      />
                      <span>Sources</span>
                    </div>
                    <div className="sources cursor-pointer" id="source-container" style={{ cursor: "pointer" }}>
                      {currentData?.page_number?.map((content, index) => {
                        const fileName = currentData.file_paths[index]?.split('/').pop();
                        // file_paths
                        // : 
                        // ["data/1/data/Environment_UPSC.pdf", "Unknown"]
                        // page_number
                        // : 
                        // ["32"]
                        // references
                        // : 
                        // []
                        // response
                        // : 
                        // "The edge effect in ecology refers to the increase in species diversity and abundance along the boundary between two ecosystems."
                        // sender_id
                        // : 
                        // 101

                        return (
                          <div
                            key={index}
                            className="source-item "
                          >
                            {/* <a className="tooltip-link" onClick={() => openPdfPopup(content?.path, currentData.page_number[index])}> */}
                            <a className="tooltip-link cursor-pointer" onClick={() => openPdfPopup(currentData.file_paths[index], currentData.page_number[index])} style={{ marginBottom: '10px', marginTop: '10px' }}>
                              {fileName}
                              <span className="tooltip">
                                <span className="tooltip-content">
                                  {currentData.response.length > 25 ? currentData.response.substring(0, 150) + "..." : currentData.response}
                                </span>
                              </span>
                            </a>
                            <span className="source-name cursor-pointer" style={{ marginBottom: '10px' }}>
                              {/* {currentData.file_paths[index]} */}
                            </span>
                            <span className="source-name cursor-pointer" style={{ marginBottom: '10px' }}>
                              <img src={chat}
                                style={{ width: "20px", marginRight: "5px", borderRadius: "50%" }} alt="Icon" className="file-icon" />
                              {"Page Number - " + content}
                            </span>
                          </div>
                        )
                      })}
                    </div>

                    <div className="icon-text">
                      <img
                        src={answer}
                        style={{ width: "30px" }}
                        alt="Icon"
                        className="rounded-circle"
                      />
                      <span>Answer</span>
                    </div>
                    <div className="description">
                      <div className="header-container mb-3">
                        <h3 className="description-title">Veranda response :</h3>
                        <div className="icon-container">
                          <i className="fa fa-thumbs-up like-icon" title="Like"></i>
                          <i className="fa fa-thumbs-down dislike-icon" title="Dislike"></i>
                          <i className="fa fa-copy copy-icon" title="Copy"></i>
                        </div>
                      </div>
                      <h6 className="text-white">
                        <Typewriter
                          options={{
                            delay: 0,
                            deleteSpeed: 50,
                            loop: false,
                          }}
                          onInit={(typewriter) => {
                            typewriter
                              .typeString(currentData.response)
                              .start();
                          }}
                        />
                      </h6>
                    </div>
                    <div className="icon-text" onClick={() => toggleDescriptionFromGenie(userInput, currentData.response)}>
                      <img
                        src={answer}
                        style={{ width: "30px" }}
                        alt="Icon"
                        className="rounded-circle"
                      />
                      <span className='genie-answer' style={{ cursor: "pointer" }}>Genie Answer</span>
                    </div>
                    {showDescription && (
                      <div className="description">
                        <div className="header-container mb-3">
                          <h3 className="description-title">Genie response :</h3>
                          <div className="icon-container">
                            <i className="fa fa-thumbs-up like-icon" title="Like"></i>
                            <i className="fa fa-thumbs-down dislike-icon" title="Dislike"></i>
                            <i className="fa fa-copy copy-icon" title="Copy"></i>
                          </div>
                        </div>
                        <h6 className="text-white">
                          <Typewriter
                            options={{
                              delay: 0,
                              deleteSpeed: 50,
                              loop: false,
                            }}
                            onInit={(typewriter) => {
                              typewriter
                                .typeString(genieAnswer)
                                .start();
                            }}
                          />
                        </h6>
                      </div>
                    )}

                  </>
                )
              )}


            </div>
          )}
          {isInfoBoxVisible && (
            <div className="col-xl-2">
              <button className="assessment-button" onClick={() => redirectToPage(userInput, currentData.response)}>
                Quick Assessment
              </button>
            </div>
          )}
          {/* Ranking Section */}
          {isSideVisible && (
            <div className="col-xl ms-3 mt-4">
              <div class="accordion accordion-primary accordion-with-icon" id="accordion-six">
                <div class="accordion-item">
                  <h2 class="accordion-header accordion-header-primary" id="headingOne61">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne6" aria-expanded="false">
                      <span class="fas fa-robot me-2"></span>
                      <span class="accordion-header-text">AI Assessment</span>
                    </button>
                  </h2>
                  <div id="collapseOne6" class="accordion-collapse collapse show" aria-labelledby="headingOne61" data-bs-parent="#accordion-six">
                    <div className="big-image-container">
                      <img src={img1} alt="Big Image" className="big-card-img" />
                      <span className="icon-overlay">
                        <img src={star} alt="AI Logo" className="ai-logo-img" /> {/* AI Logo */}
                      </span>
                    </div>
                    <div className="image-row-row">
                      <div className="image-container">
                        <img src={img2} alt="Active Listening Skills" className="small-card-img" />
                        <span className="icon-overlay">
                          <img src={star} alt="AI Logo" className="ai-logo-img" /> {/* AI Logo */}
                        </span>
                      </div>
                      <div className="image-container">
                        <img src={img3} alt="Active Listening Skills" className="small-card-img" />
                        <span className="icon-overlay">
                          <img src={star} alt="AI Logo" className="ai-logo-img" /> {/* AI Logo */}
                        </span>
                      </div>
                    </div>
                    <div className="image-row-row">
                      <div className="image-container">
                        <img src={img4} alt="Active Listening Skills" className="small-card-img" />
                        <span className="icon-overlay">
                          <img src={star} alt="AI Logo" className="ai-logo-img" /> {/* AI Logo */}
                        </span>
                      </div>
                      <div className="image-container">
                        <img src={img5} alt="Active Listening Skills" className="small-card-img" />
                        <span className="icon-overlay">
                          <img src={star} alt="AI Logo" className="ai-logo-img" /> {/* AI Logo */}
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header accordion-header-info" id="headingTwo6">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo6" aria-expanded="false">
                      <span class="fa fa-medal mb-2"></span>
                      <span class="accordion-header-text">Levels</span>
                    </button>
                  </h2>
                  <div id="collapseTwo6" class="accordion-collapse collapse" aria-labelledby="headingTwo6" data-bs-parent="#accordion-six">
                    <div class="col-12">
                      <div class="card active_users">
                        <div class="card-header bg-primary border-0 pb-0">
                          <h4 class="card-title text-white">Active Users</h4>
                          <span id="counter"></span>
                        </div>
                        <div class="bg-primary">
                          <canvas id="activeUser" width="30" height="80px" style={{ display: 'block', boxsizing: 'border-box', height: '30px', width: '80px' }}></canvas>
                        </div>
                        <div class="card-body pt-0">
                          <div class="list-group-flush mt-4">
                            <div class="list-group-item bg-transparent d-flex justify-content-between px-0 py-1 fw-semi-bold border-top-0 border-0 border-bottom">
                              <p class="mb-0">Top Active Pages</p>
                              <p class="mb-0">Active Users</p>
                            </div>
                            <div class="list-group-item bg-transparent d-flex justify-content-between px-0 py-1 border-0 border-bottom">
                              <p class="mb-0">/bootstrap-themes/</p>
                              <p class="mb-0">3</p>
                            </div>
                            <div class="list-group-item bg-transparent d-flex justify-content-between px-0 py-1 border-0 border-bottom">
                              <p class="mb-0">/tags/html5/</p>
                              <p class="mb-0">3</p>
                            </div>
                            <div class="list-group-item bg-transparent d-xxl-flex justify-content-between px-0 py-1 d-none">
                              <p class="mb-0">/</p>
                              <p class="mb-0">2</p>
                            </div>
                            <div class="list-group-item bg-transparent d-xxl-flex justify-content-between px-0 py-1 d-none">
                              <p class="mb-0">/preview/falcon/dashboard/</p>
                              <p class="mb-0">2</p>
                            </div>
                            <div class="list-group-item bg-transparent d-flex justify-content-between px-0 py-1 border-0 border-bottom">
                              <p class="mb-0">/100-best-themes...all-time/</p>
                              <p class="mb-0">1</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="accordion-item">
                  <h2 class="accordion-header accordion-header-primary" id="headingThree6">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree6" aria-expanded="false">
                      <span class="accordion-header-icon"></span>
                      <span class="accordion-header-text">Accordion Header Three</span>
                    </button>
                  </h2>
                  <div id="collapseThree6" class="accordion-collapse collapse" aria-labelledby="headingThree6" data-bs-parent="#accordion-six">
                    <div class="accordion-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>


    </div >
  );
}

export default KnowledgeScreen;
