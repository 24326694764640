import React, { useEffect, useState } from 'react';
import NavBar from '../../Layouts/NavBar';
import Header from '../../Layouts/Header';
import SideBar from '../../Layouts/SideBar';
import Footer from '../../Layouts/Footer';
import Select from 'react-select';
import { apiURL } from '../../CommonConfig/BaseUrl';
import Swal from 'sweetalert2';
import axios from 'axios';

function CreateTeam() {

    const [students, setStudents] = useState([]);
    const [studentBasedOnTeam, setStudentBasedOnTeam] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");

    const [teamSearchTerm, setTeamSearchTerm] = useState("");
    const [teamItemsPerPage, setTeamItemsPerPage] = useState(5);
    const [teamCurrentPage, setTeamCurrentPage] = useState(1);

    const [options, setOptions] = useState([]);
    const [teamName, setTeamName] = useState('');
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [teams, setTeams] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const handleSubmit = async (e) => {

        if (uploadedFiles.length === 0) {
            showAlert('Please select at least one file to upload', 'warning');
            return;
        }

        const formData = new FormData();
        uploadedFiles.forEach((file) => {
            formData.append('file', file);
        });

        try {
            const response = await axios.post(`${apiURL}/upload_team_lists`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                if (response.data.file_url != "") {
                    showAlert('File(s) uploaded successfully!', 'success');
                    getData();
                } else {
                    showAlert('File upload failed', 'error');
                }

            } else {
                showAlert('File upload failed', 'error');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            Swal.fire({
                title: 'Error uploading file',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#2EA87E',
            });
        }
    };
    const handleCreateTeam = async (event) => {
        // event.preventDefault();

        const payload = {
            org_id: 0,
            team_name: teamName,
            students: selectedStudents.map(student => student.value),
            created_by: 0
        };

        try {
            const response = await fetch(`${apiURL}/create_team`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            const result = await response.json();
            if (result.id != null) {
                showAlert('Team created successfully', 'success');
                getData();
            } else {
                showAlert('Team created failed', 'error');
            }
        } catch (error) {
            Swal.fire({
                title: 'Error uploading file',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#2EA87E',
            });
        }
    };

    const showAlert = (message, type) => {
        Swal.fire({
            title: message,
            icon: type,
            confirmButtonText: 'OK',
            confirmButtonColor: '#2EA87E',
        });
    };

    const getData = async () => {
        try {
            const response = await axios.get(`${apiURL}/get_all_team`);

            if (response.status === 200 && response.data && response.data.data) {
                const sortedData = response.data.data.sort((a, b) => a.id - b.id);
                setTeams(sortedData);
            } else {
                // toastr.error('Failed to load students');
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
        }
    };

    const handleTeamSearch = (e) => {
        setTeamSearchTerm(e.target.value);
        setTeamCurrentPage(1); // Reset to first page on new search
    };

    const handleTeamItemsPerPageChange = (e) => {
        setTeamItemsPerPage(Number(e.target.value));
        setTeamCurrentPage(1); // Reset to first page on items-per-page change
    };

    const handleTeamPageChange = (newPage) => {
        setTeamCurrentPage(newPage);
    };

    const filteredTeams = teams.filter(
        (team) =>
            team.team_name.toLowerCase().includes(teamSearchTerm.toLowerCase())
    );

    const totalTeamItems = filteredTeams.length;
    const totalTeamPages = Math.ceil(totalTeamItems / teamItemsPerPage);
    const paginatedTeams = filteredTeams.slice(
        (teamCurrentPage - 1) * teamItemsPerPage,
        teamCurrentPage * teamItemsPerPage
    );


    const handleDownloadTemplate = () => {
        window.open(`${apiURL}/download-Team_template`, "_blank");
    };

    const handleFileUpload = (e) => {
        const files = Array.from(e.target.files);
        setUploadedFiles(files);
    };

    const showAllStudentsBasedOnTeam = async (teamId) => {
        // console.log(teamId);
        try {
            const response = await axios.get(`${apiURL}/get_team_by_id/${teamId}`);

            if (response.status === 200 && response.data && response.data.data) {
                const studentsListBasedOnTeam = response.data.data.students
                setStudentBasedOnTeam(studentsListBasedOnTeam)

                // const sortedData = response.data.data.sort((a, b) => a.id - b.id);
                // setStudents(sortedData);
                // setCount(sortedData.length);
            } else {
                // toastr.error('Failed to load students');
            }
        } catch (error) {
            console.error('Error fetching students:', error);
            // toastr.error('An error occurred while fetching students');
        } finally {
        }
    };



    useEffect(() => {
        showAllStudentsBasedOnTeam();
    }, []);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const filteredStudents = students.filter(
        (student) =>
            student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            student.studentid.toLowerCase().includes(searchTerm.toLowerCase()) ||
            student.number.toLowerCase().includes(searchTerm.toLowerCase()) ||
            student.emailid.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalItems = filteredStudents.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const paginatedStudents = filteredStudents.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();
        const hours = dateObj.getHours();
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        const formattedHour = hours % 12 || 12;

        return `${day} / ${month} / ${year} - ${formattedHour}:${minutes} ${ampm}`;
    };

    return (
        <div id="main-wrapper">
            <NavBar />
            <Header />
            <SideBar />
            <div class="content-body default-height">
                <div class="container-fluid">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Team Creation</h4>
                        </div>

                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="align-items-center d-flex">
                                    <div>
                                        <button type="button" class="btn btn-primary mb-2" data-bs-toggle="modal" data-bs-target="#basicModal">Add Team</button>
                                        <button type="button" class="btn btn-primary mb-2 mx-4" data-bs-toggle="modal" data-bs-target="#basicModal1">Add Bulk Team</button>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                            <div class="modal fade" id="basicModal" style={{ display: 'none' }} data-bs-backdrop="static" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">Create Team</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal">
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleCreateTeam}>
                                                <div className="mb-3">
                                                    <label className="form-label">Select Student</label>
                                                    <Select
                                                        options={options}
                                                        isMulti
                                                        value={selectedStudents}
                                                        onChange={setSelectedStudents}
                                                        placeholder="Please select students"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Name of Team</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Team"
                                                        value={teamName}
                                                        onChange={(e) => setTeamName(e.target.value)}
                                                    />
                                                </div>
                                                <button type="submit" className="btn btn-primary">Create</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal fade" id="basicModal1" data-bs-backdrop="static" style={{ display: 'none' }} aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">Create Bulk Team</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal">
                                            </button>
                                        </div>
                                        <div class="">
                                            <div class="card-body">
                                                <div class="basic-form">

                                                    {/* <div class="row">
                                                            <div class="mb-3 col-md-12">
                                                                <label class="form-label">Name of Student</label>
                                                                <input type="text" class="form-control" placeholder="Enter Name" value={studentname} onChange={(e) => setStudentname(e.target.value)} />
                                                            </div>
                                                            <div class="mb-3 col-md-12">
                                                                <label class="form-label">Name of Department</label>
                                                                <input type="text" class="form-control" placeholder="Enter Department" value={departmantname} onChange={(e) => setDepartmantname(e.target.value)} />
                                                            </div>
                                                            <div class="mb-3 col-md-12">
                                                                <label class="form-label">Year</label>
                                                                <input type="text" class="form-control" placeholder="Year" value={year} onChange={(e) => setYear(e.target.value)} />
                                                            </div>
                                                            <div class="mb-3 col-md-12">
                                                                <label class="form-label">Semester</label>
                                                                <input type="text" class="form-control" placeholder="Enter Semester" value={semester} onChange={(e) => setSemester(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="mb-3 col-md-12">
                                                                <label class="form-label">Roll No</label>
                                                                <input type="text" class="form-control" placeholder="Enter Roll No" value={rollNo} onChange={(e) => setRollNo(e.target.value)} />
                                                            </div>
                                                        </div> */}

                                                    <div class="row mt-3" style={{ display: 'flex', justifyContent: 'end' }}>
                                                        <div className='col-md-4'>
                                                            <button type="submit" style={{ float: 'right' }} class="btn btn-primary" onClick={handleDownloadTemplate}>Download Template</button>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Name of Team</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Team"
                                                            value={teamName}
                                                            onChange={(e) => setTeamName(e.target.value)}
                                                        />
                                                    </div>
                                                    <div class="row">
                                                        {/* <h4 style={{textAlign:'center'}}>(OR)</h4> */}
                                                        <div class="mb-3 col-md-12">
                                                            <label class="form-label">Upload Document</label>
                                                            <input type="file" class="form-control" onChange={handleFileUpload} />
                                                        </div>
                                                    </div>
                                                    <button type="submit" class="btn btn-primary" onClick={handleSubmit}>Create</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="">
                            <div class="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <div className="col-xl-6">
                                        <div className="input-group mb-2">
                                            <div className="input-group-text"><i className="fas fa-search"></i></div>
                                            <input
                                                type="text"
                                                className="form-control wide"
                                                placeholder="Search by Team Name"
                                                value={teamSearchTerm}
                                                onChange={handleTeamSearch}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-3">
                                        <select
                                            className="form-control wide"
                                            value={teamItemsPerPage}
                                            onChange={handleTeamItemsPerPageChange}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <option value="5">5 - items per page</option>
                                            <option value="10">10 - items per page</option>
                                            <option value="15">15 - items per page</option>
                                            <option value="20">20 - items per page</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <table className="table table-responsive-md">
                                        <thead className="mx-auto text-center">
                                            <tr>
                                                <th>S.No</th>
                                                <th>Team Name</th>
                                                <th>Team ID</th>
                                                <th>Student Count</th>
                                                <th>Student Details</th>
                                                <th>Created At</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="mx-auto text-center">
                                            {paginatedTeams.length > 0 ? (
                                                paginatedTeams.map((team, index) => (
                                                    <tr key={team.id}>
                                                        <td>{(teamCurrentPage - 1) * teamItemsPerPage + index + 1}</td>
                                                        <td>{team.team_name}</td>
                                                        <td>{team.id}</td>
                                                        <td>{team.students.length}</td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-xs btn-success light"
                                                                data-bs-toggle="modal"
                                                                data-bs-target=".std-view-modal"
                                                                onClick={() => showAllStudentsBasedOnTeam(team.id)}
                                                            >
                                                                View
                                                            </button>
                                                        </td>
                                                        <td>{formatDate(team.created_at)}</td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-xs btn-warning light"
                                                                data-bs-toggle="modal"
                                                                data-bs-target=".std-edit-modal"
                                                            >
                                                                {/* <i className="fas fa-pen"></i> */}Edit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="6">No Teams available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <div className="text-dark">
                                        Showing <b className="text-primary">{paginatedTeams.length}</b> of{" "}
                                        <b className="text-primary">{totalTeamItems}</b> entries
                                    </div>
                                    <div className="text-dark">
                                        <span className="me-4">
                                            Page <b className="text-primary">{teamCurrentPage}</b> of{" "}
                                            <b className="text-primary">{totalTeamPages}</b>
                                        </span>
                                        <button
                                            className="btn btn-xs btn-primary"
                                            onClick={() => handleTeamPageChange(teamCurrentPage - 1)}
                                            disabled={teamCurrentPage === 1 || totalTeamPages === 0}
                                        >
                                            <i className="fas fa-chevron-left"></i>
                                        </button>
                                        <button
                                            className="btn btn-xs btn-primary ms-2"
                                            onClick={() => handleTeamPageChange(teamCurrentPage + 1)}
                                            disabled={teamCurrentPage === totalTeamPages || totalTeamPages === 0}
                                        >
                                            <i className="fas fa-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>


                                {/* MODAL  TO  VIEW  STUDENT  DETAILS */}
                                <div class="modal fade std-view-modal" tabindex="-1" data-bs-backdrop="static" style={{ display: 'none' }} aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h3 class="modal-title text-primary">Student Details</h3>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal">
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div className="d-flex align-items-center justify-content-between mb-4">
                                                    <div className="col-xl-6">
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-text"><i className="fas fa-search"></i></div>
                                                            <input
                                                                type="text"
                                                                className="form-control wide"
                                                                placeholder="Search by Name / Student ID / Mobile / E-Mail ID"
                                                                value={searchTerm}
                                                                onChange={handleSearch}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <select
                                                            className="form-control wide"
                                                            value={itemsPerPage}
                                                            onChange={handleItemsPerPageChange}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <option value="5">5 - items per page</option>
                                                            <option value="10">10 - items per page</option>
                                                            <option value="15">15 - items per page</option>
                                                            <option value="20">20 - items per page</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="table-responsive" style={{ overflow: 'auto', minHeight: '50vh', maxHeight: '50vh' }}>
                                                    <table className="table table-responsive-md">
                                                        <thead className="mx-auto text-center">
                                                            <tr>
                                                                <th>S.No</th>
                                                                <th>Student Name</th>
                                                                <th>Student ID</th>
                                                                <th>Email ID</th>
                                                                <th>Password</th>
                                                                <th>Mobile Number</th>
                                                                <th>Department</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="mx-auto text-center">
                                                            {studentBasedOnTeam?.map((student, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{student.name}</td>
                                                                    <td>{student.studentid}</td>
                                                                    <td>{student.emailid}</td>
                                                                    <td>{student.password}</td>
                                                                    <td>{student.number}</td>
                                                                    <td>{student.department}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="d-flex justify-content-between align-items-center mt-3">
                                                    <div className="text-dark">
                                                        Showing <b className="text-primary">{studentBasedOnTeam.length}</b> of{" "}
                                                        <b className="text-primary">{studentBasedOnTeam.length}</b> entries
                                                    </div>
                                                    <div className="text-dark">
                                                        <span className="me-4">
                                                            Page <b className="text-primary">{currentPage}</b> of{" "}
                                                            <b className="text-primary">{totalPages}</b>
                                                        </span>
                                                        <button
                                                            className="btn btn-xs btn-primary"
                                                            onClick={() => handlePageChange(currentPage - 1)}
                                                            disabled={currentPage === 1 || totalPages === 0}
                                                        >
                                                            <i className="fas fa-chevron-left"></i>
                                                        </button>
                                                        <button
                                                            className="btn btn-xs btn-primary ms-2"
                                                            onClick={() => handlePageChange(currentPage + 1)}
                                                            disabled={currentPage === totalPages || totalPages === 0}
                                                        >
                                                            <i className="fas fa-chevron-right"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* MODAL  TO  EDIT  STUDENT  DETAILS */}
                                <div class="modal fade std-edit-modal" tabindex="-1" data-bs-backdrop="static" style={{ display: 'none' }} aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h3 class="modal-title text-primary">Student Details</h3>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal">
                                                </button>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-between mt-4 mx-5">
                                                <div class="align-items-center d-flex">
                                                    <div>
                                                        <button type="button" class="btn btn-primary mb-2" data-bs-toggle="modal" data-bs-target="#basicModal">Add Users to Team</button>
                                                        <button type="button" class="btn btn-primary mb-2 mx-4" data-bs-toggle="modal" data-bs-target="#basicModal1">Add Bulk Users to Team</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-body">
                                                <div className="d-flex align-items-center justify-content-between mb-4">
                                                    <div className="col-xl-6">
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-text"><i className="fas fa-search"></i></div>
                                                            <input
                                                                type="text"
                                                                className="form-control wide"
                                                                placeholder="Search by Name / Student ID / Mobile / E-Mail ID"
                                                                value={searchTerm}
                                                                onChange={handleSearch}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <select
                                                            className="form-control wide"
                                                            value={itemsPerPage}
                                                            onChange={handleItemsPerPageChange}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <option value="5">5 - items per page</option>
                                                            <option value="10">10 - items per page</option>
                                                            <option value="15">15 - items per page</option>
                                                            <option value="20">20 - items per page</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="table-responsive" style={{ overflow: 'auto', minHeight: '50vh', maxHeight: '50vh' }}>
                                                    <table className="table table-responsive-md">
                                                        <thead className="mx-auto text-center">
                                                            <tr>
                                                                <th>S.No</th>
                                                                <th>Student Name</th>
                                                                <th>Student ID</th>
                                                                <th>Email ID</th>
                                                                <th>Password</th>
                                                                <th>Mobile Number</th>
                                                                <th>Department</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="mx-auto text-center">
                                                            {studentBasedOnTeam?.map((student, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{student.name}</td>
                                                                    <td>{student.studentid}</td>
                                                                    <td>{student.emailid}</td>
                                                                    <td>{student.password}</td>
                                                                    <td>{student.number}</td>
                                                                    <td>{student.department}</td>
                                                                    <td>
//                                                                         <button type="button" class="btn btn-xs btn-warning light" title='Edit User'>
//                                                                             <i className='fas fa-pen'></i>
//                                                                         </button>
                                                                        <button type="button" class="btn btn-xs btn-danger light ms-2" title='Delete User'>
                                                                            <i className='fas fa-trash'></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="d-flex justify-content-between align-items-center mt-3">
                                                    <div className="text-dark">
                                                        Showing <b className="text-primary">{studentBasedOnTeam.length}</b> of{" "}
                                                        <b className="text-primary">{studentBasedOnTeam.length}</b> entries
                                                    </div>
                                                    <div className="text-dark">
                                                        <span className="me-4">
                                                            Page <b className="text-primary">{currentPage}</b> of{" "}
                                                            <b className="text-primary">{totalPages}</b>
                                                        </span>
                                                        <button
                                                            className="btn btn-xs btn-primary"
                                                            onClick={() => handlePageChange(currentPage - 1)}
                                                            disabled={currentPage === 1 || totalPages === 0}
                                                        >
                                                            <i className="fas fa-chevron-left"></i>
                                                        </button>
                                                        <button
                                                            className="btn btn-xs btn-primary ms-2"
                                                            onClick={() => handlePageChange(currentPage + 1)}
                                                            disabled={currentPage === totalPages || totalPages === 0}
                                                        >
                                                            <i className="fas fa-chevron-right"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CreateTeam