import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import toastr from 'toastr';
import vlogo from '../../imgs/verandaLog.png';
import ContentLoader, { Code } from 'react-content-loader';
import ReactConfetti from 'react-confetti';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Doughnut } from 'react-chartjs-2';
import Typewriter from 'typewriter-effect';
import { apiURL } from '../../CommonConfig/BaseUrl';
import { useLocation } from 'react-router-dom';

function AssessmentMcq() {
  const [startInterview, setStartInterview] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userResponses, setUserResponses] = useState([]);
  const [showFinishModal, setShowFinishModal] = useState(false);
  const [score, setScore] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [tabSwitchCount, setTabSwitchCount] = useState(0);
  const isDataFetchedRef = useRef(false);


  const donutData = {
    labels: ['Correct', 'Incorrect'],
    datasets: [
      {
        label: 'Answers',
        data: [score, questions.length - score],
        backgroundColor: ['#28a745', '#dc3545'],
        hoverOffset: 4,
      },
    ],
  };

  const donutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  const MyCodeLoader = () => <Code />;

  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query');
  const response = new URLSearchParams(location.search).get('response');

  

  useEffect(() => {
    if (query && response && !isDataFetchedRef.current) {
      // Prevent re-fetching if data has already been fetched
      isDataFetchedRef.current = true; 
      getAnsData(query, response);
    }
  }, [query, response]);

  const getAnsData = (query, response) => {
    axios
      .post(`${apiURL}/generate-mcqs`, { query, response })
      .then((res) => {
        if (res.status === 200 && res.data.mcqs) {
          const transformedQuestions = res.data.mcqs.map((item) => ({
            question: item.question,
            options: item.options,
            answer: item.correct_answer,
            isDescriptive: false,
          }));
          setQuestions(transformedQuestions);
          setUserResponses(Array(transformedQuestions.length).fill(null));
          setLoading(false);
        } else {
          toastr.error('Failed to load questions. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        toastr.error('Error connecting to the server.');
        setLoading(false);
      });
  };

  const handleAnswer = (index, answer) => {
    const updatedResponses = [...userResponses];
    updatedResponses[index] = answer;
    setUserResponses(updatedResponses);
  };

  const handleNextQuestion = () => {
    if (userResponses[currentQuestionIndex] === null || userResponses[currentQuestionIndex] === '') {
      toastr.warning('Please select an answer before proceeding.');
      return;
    }
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setShowFinishModal(true); 
    }
  };

  const handleFinishTest = () => {
    if (userResponses.some((response) => response === null || response === '')) {
      toastr.warning('Please answer all questions before finishing the test.');
      return;
    }

    let correctAnswers = 0;
    userResponses.forEach((response, index) => {
      if (response === questions[index].answer) {
        correctAnswers++;
      }
    });
    setScore(correctAnswers);
    setShowFinishModal(false); 
    setStartInterview(false); 
  };

  const startTimer = () => {
    const totalTime = 240; 
    setTimeLeft(totalTime);  
  };
  
  useEffect(() => {
    if (questions.length > 0) {
      startTimer(); 
    }
  }, [questions]);

  useEffect(() => {
    if (timeLeft === null || timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);  
    }, 1000);

    return () => clearInterval(timer); 
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft === 0) {
      Swal.fire({
        title: 'Time Out',
        text: 'Assessment Ended!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        cancelButtonText: 'Review!',
      }).then((result) => {
        if (result.isConfirmed) {
          setStartInterview(false);
          setScore(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          setStartInterview(false);
          setScore(true);
        }
      });
    }
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

 

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setTabSwitchCount((prevCount) => prevCount + 1);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  return (
    <>
      <style>
        {`
  body {
    background-color: #2b2d42;
    color: #edf2f4;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .bg-green {
    background-color: #2ea87e;
  }
  .text-green {
    color: #2ea87e;
  }
  .btn-green {
    background-color: #2ea87e;
  }
  .border-green {
    border: 1px solid #2ea87e;
  }
  .border-right-green {
    border-right: 1px solid #2ea87e;
  }
  .border-left-green {
    border-left: 1px solid #2ea87e;
  }
  .border-top-green {
    border-top: 1px solid #2ea87e;
  }
  .border-bottom-green {
    border-bottom: 1px solid #2ea87e;
  }
  .card {
    background: rgba(58, 59, 60, 0.7);
    backdrop-filter: blur(10px);
    border: none;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
  }
  .card-header {
    background: rgba(74, 78, 105, 0.7);
    color: #edf2f4;
    font-weight: bold;
  }
  `}
      </style>

      <div className="card-header bg-primary py-3 d-flex justify-content-between">
        <img src={vlogo} style={{ width: '5%' }} />
        <h3 className="text-white m-0 p-0">Assessment Hub</h3>
      </div>
      <div className='card-body'>
        {/* Main Assessment Content */}
        {startInterview && (
          loading ? (
            <MyCodeLoader />
          ) : (
            <div className="card">
              <div className="card-body">
                <div className="row">
                  {/* <div className="col-xl-4">
                        <div className='mx-4'>
                        </div>
                      </div> */}
                  <div className="col-xl-12">
                    <div className='mx-4'>
                      <span className='d-flex align-items-center flex-wrap justify-content-between'>
                        <h3 className="m-0 p-0 text-primary">Question - Set 1</h3>
                        <h4 className="m-0 p-0 text-warning">
                          Time Remaining
                          <span className="text-danger">
                            {" - "}
                            {formatTime(timeLeft)}
                            <img
                              src="https://cdn-icons-gif.flaticon.com/8721/8721066.gif"
                              style={{ width: '30px' }}
                              alt="Icon"
                              className="rounded-circle ms-2"
                            />
                          </span>
                        </h4>
                      </span>
                    </div>
                    <hr />

                    {/* Hub question and user response component */}
                    <div className='card-body' style={{ maxHeight: '50vh', minHeight: '50vh', overflow: 'auto' }}>
                      {currentQuestionIndex < questions.length ? (
                        <div className="text-center">
                          <p className="mb-4 fs-3 text-white">{questions[currentQuestionIndex].question}</p>
                          <div className="row mb-4 mx-5 px-5">
                          
                          {
                            questions[currentQuestionIndex].options.map((option, index) => (
                                <div className="col-xl-6 mb-3" key={index}>
                                <Button
                                    className={`btn bg-${userResponses[currentQuestionIndex] === option ? "green" : "white"} text-${userResponses[currentQuestionIndex] === option ? "white" : "dark"} border-green w-100`}
                                    onClick={() => handleAnswer(currentQuestionIndex, option)}
                                >
                                    {option}
                                </Button>
                                </div>
                            ))
                            }

                          </div>
                          <div className="mx-auto text-center mt-5">
                            {currentQuestionIndex > 0 && (
                              <div className="btn btn-primary me-4" onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)} role="button" aria-label="Previous">
                                Previous
                              </div>
                            )}
                            {currentQuestionIndex < questions.length - 1 ? (
                              <div className="btn btn-primary" onClick={handleNextQuestion} role="button" aria-label="Next">
                                Next
                              </div>
                            ) : (
                              <div className="btn btn-warning" onClick={handleNextQuestion} role="button" aria-label="Finish">
                                Finish
                              </div>
                            )}
                          </div>

                        </div>

                      ) : (
                        <p className="m-0 p-0 text-info fs-5">Fetching Assessment Questions,     Please Wait...</p>
                      )}

                    </div>
                    <div className='card-footer border-top-green'>
                      <div className="border-green rounded p-3">
                        <div className="d-flex align-items-center justify-content-between mx-4">
                          <p className="m-0 p-0 text-white fs-5">Questions Remaining :</p>
                          <p className="m-0 p-0 text-warning fs-5 fw-bold">
                            {questions.length - currentQuestionIndex} / {questions.length}
                          </p>
                        </div>
                        <hr />
                        <div className="d-flex align-items-center justify-content-between mx-4">
                          <p className="m-0 p-0 text-white fs-5">Tabs Switched :</p>
                          <p className="m-0 p-0 text-warning fs-5 fw-bold">
                            {tabSwitchCount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
       {/* Finish Confirmation Modal */}
       <Modal show={showFinishModal} onHide={() => setShowFinishModal(false)} backdrop="static">
        <Modal.Header className='bg-primary'>
          <Modal.Title className='d-flex align-items-center'>
            <img src='https://cdn-icons-gif.flaticon.com/15578/15578568.gif' style={{ width: "40px" }} alt="Icon" className="rounded-circle" />
            <h4 className='text-white m-0 p-0 ms-3'>Finish Assessment ?</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=' bg-dark'>
          <p className='text-white fs-5'>Are you sure you want to finish the Assessment ?<br />You will not be able to go back to previous questions !!</p>
          <hr className='text-green' />
          <div className="d-flex justify-content-between">
            <div className="btn btn-primary" onClick={() => setShowFinishModal(false)}>
              Cancel
            </div>
            <div className="btn btn-primary" onClick={handleFinishTest}>
              Finish
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Confetti */}
      {showConfetti && (
        <ReactConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={200}
          gravity={0.3}
        />
      )}
      {/* Summary Modal */}
      <Modal show={score} onHide={() => setScore(true)} backdrop="static">
        <Modal.Header className='bg-primary'>
          <Modal.Title className='d-flex align-items-center'>
            <h4 className='text-white m-0 p-0 me-5'>Assessment Summary</h4>
            <img src='https://cdn-icons-gif.flaticon.com/10971/10971313.gif' style={{ width: "40px" }} alt="Icon" className="rounded-circle" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-dark'>
          <div className='text-white'>
            <span className='d-flex align-items-center justify-content-between'><p className="mb-1">Your score : </p><b className='text-uppercase '>{score} out of {questions.length}</b></span>
            <span className='d-flex align-items-center justify-content-between'><p className="mb-1">Correct answers : </p><b className='text-success'>{score}</b></span>
            <span className='d-flex align-items-center justify-content-between'><p className="mb-1">Incorrect answers : </p><b className='text-danger'>{questions.length - score}</b></span>
            <span className='d-flex align-items-center justify-content-between'><p className="mb-1">Average time taken per question : </p><b className='text-danger'>43 sec</b></span>
          </div>
          <hr className='text-green' />
          <div style={{ height: '200px' }}>
            <Doughnut data={donutData} options={donutOptions} />
          </div>
          <hr className='text-green' />
          {/* <span className='mx-auto text-center fs-5 text-primary'><b>Thank you for participating in the Assessment !</b></span> */}
          <span className='mx-auto text-center fs-5 text-primary'><b>
            <Typewriter
              options={{
                delay: 10,
                deleteSpeed: 50,
                loop: false,
              }}
              onInit={(typewriter) => {
                typewriter
                  .typeString("Thank you for participating in the Assessment !")
                  .start();
              }}
            />
          </b></span>
        </Modal.Body>
        <Modal.Footer className='bg-dark'>
          <a className="btn btn-primary text-center mx-auto" href='/KnowledgeScreen'>Close</a>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AssessmentMcq;
