
const Signin = '/'; 
const Dashboard = '/Dashboard'; 
const InterviewBot = '/InterviewBot';
const AssessmentMcq = '/AssessmentMcq';
const KnowledgeBotConfig = '/KnowledgeBotConfig';
const interviewBotCongfig= '/InterviewBotConfig';
const BotSummary='/BotSummary'
const DocSummary='/DocSummary'
const KnowledgeScreen='/KnowledgeScreen'
const StudentProfile = '/StudentProfile'
const HubInfo='/HubInfo'
const HubProfile='/HubProfile'
const StudentCreation='/StudentCreation'
const CreateTeam='/CreateTeam'
const SignIn='/'
const InterviewSummary='/InterviewSummary'
const AssignTeam='/AssignTeam'
const BatchConfig ='/BatchConfig'

export const BaseRoutes = {
    Signin, 
    Dashboard,
    InterviewBot,
    KnowledgeBotConfig,
    interviewBotCongfig,
    BotSummary,
    KnowledgeScreen,
    StudentProfile,
    HubInfo,
    HubProfile,
    StudentCreation,
    CreateTeam,
    SignIn,
    DocSummary,
    InterviewSummary,
    AssignTeam,
    BatchConfig,
    AssessmentMcq
}