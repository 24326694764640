import React from 'react';
import Footer from '../Layouts/Footer';
import SideBar from '../Layouts/SideBar';
import Header from '../Layouts/Header';
import NavBar from '../Layouts/NavBar';
import CountUp from 'react-countup';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';

Chart.register(ArcElement);

function StudentProfile() {

    const donutData = {
        labels: ['Passed', 'Failed'],
        datasets: [
            {
                label: '  In Assessment',
                data: [24, 15], // Static data example
                backgroundColor: ['#28a745', '#dc3545'],
                hoverOffset: 4,
            },
        ],
    };

    const donutOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
    };

    return (
        <>
            <div id='main-wrapper'>
                <NavBar />
                <Header />
                <SideBar />
                <div class="content-body default-height">
                    <div class="container-fluid">
                        <div className='card'>
                            <div className='card-header'>
                                <h4 className='m-0'>Student Profile / Summary</h4>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-xl me-3'>
                                        <label className='h4 text-light ms-1'>Student Name</label>
                                        <select class="me-sm-2 default-select form-control wide">
                                            <option selected="">Select a Student</option>
                                            <option value="1">Akash</option>
                                            <option value="2">Arun</option>
                                            <option value="3">Nishanth</option>
                                            <option value="4">Rithick</option>
                                        </select>
                                    </div>
                                    <div className='col-xl card'>
                                        <div className='d-flex align-items-center'>
                                            <i className='fas fa-clock fs-1 border rounded p-2 text-warning bg-warning-light'></i>
                                            <span class="ms-5">
                                                <h5 class="mt-3"><CountUp end={39} duration={4} /></h5>
                                                <p className='text-dark'>Assessment Attended</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-xl card mx-xl-3'>
                                        <div className='d-flex align-items-center'>
                                            <i className='fas fa-times-circle fs-1 border rounded p-2 text-danger bg-danger-light'></i>
                                            <span class="ms-5">
                                                <h5 class="mt-3"><CountUp end={15} duration={4} /></h5>
                                                <p className='text-dark'>Assessment Failed</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-xl card'>
                                        <div className='d-flex align-items-center'>
                                            <i className='fas fa-check-circle fs-1 border rounded p-2 text-success bg-success-light'></i>
                                            <span class="ms-5">
                                                <h5 class="mt-3"><CountUp end={24} duration={4} /></h5>
                                                <p className='text-dark'>Assessment Passed</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-xl me-3 card'>
                                        <div className="text-center my-3">
                                            <i className='fas fa-user-circle text-info fs-1 my-4'></i>
                                            <p className="m-0 fs-4 fw-bold">Rithick R</p>
                                            <p className="m-0 text-dark fs-6">Student</p>
                                        </div>
                                        <div className="border-green rounded p-3">
                                            <div className="d-flex align-items-center justify-content-between mx-4">
                                                <p className="m-0 p-0 text-dark fs-5">ID</p>
                                                <p className="m-0 p-0 fs-5 fw-bold"># 04</p>
                                            </div>
                                            <hr />
                                            <div className="d-flex align-items-center justify-content-between mx-4">
                                                <p className="m-0 p-0 text-dark fs-5">Department</p>
                                                <p className="m-0 p-0 fs-5 fw-bold">UPSC</p>
                                            </div>
                                            <hr />
                                            <div className="d-flex align-items-center justify-content-between mx-4">
                                                <p className="m-0 p-0 text-dark fs-5">Teacher</p>
                                                <p className="m-0 p-0 fs-5 fw-bold">Nishanth</p>
                                            </div>
                                            <hr />
                                            <div className="d-flex align-items-center justify-content-between mx-4">
                                                <p className="m-0 p-0 text-dark fs-5">Joined Date</p>
                                                <p className="m-0 p-0 fs-5 fw-bold">04-10-2024</p>
                                            </div>
                                            <hr />
                                            <div className="d-flex align-items-center justify-content-between mx-4">
                                                <p className="m-0 p-0 text-dark fs-5">Total Hours</p>
                                                <p className="m-0 p-0 fs-5 fw-bold"><CountUp end={182} duration={4} /></p>
                                            </div>
                                            <hr />
                                            {/* <div className="d-flex align-items-center justify-content-between mx-4">
                                                <p className="m-0 p-0 text-dark fs-5">Attended Hours</p>
                                                <p className="m-0 p-0 fs-5 fw-bold"><CountUp end={150} duration={4} /></p>
                                            </div>
                                            <hr />
                                            <div className="d-flex align-items-center justify-content-between mx-4">
                                                <p className="m-0 p-0 text-dark fs-5">Missed Hours</p>
                                                <p className="m-0 p-0 fs-5 fw-bold"><CountUp end={32} duration={4} /></p>
                                            </div> */}

                                            {/* Donut Chart */}
                                            <h5 className='text-center mt-3'>Assessment Results</h5>
                                            <div style={{ height: '250px' }}>
                                                <Doughnut data={donutData} options={donutOptions} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xl-8 card p-3'>
                                        <div className='d-flex align-items-center justify-content-between mb-4'>
                                            <div className='col-xl ms-2'>
                                                <div class="input-group">
                                                    <div class="input-group-text"><i className='fas fa-search'></i></div>
                                                    <input type="text" class="form-control wide" placeholder="Search" />
                                                </div>
                                            </div>
                                            <div className='col-xl mx-3'>
                                                <input type="date" class="form-control wide" placeholder="Search" />
                                            </div>
                                            <div className='col-xl me-2'>
                                                <select className='form-control wide' id="itemsPerPage" style={{ cursor: 'pointer' }}>
                                                    <option value="5"> 05 - items per page</option>
                                                    <option value="10"> 10 - items per page</option>
                                                    <option value="15"> 15 - items per page</option>
                                                    <option value="20"> 20 - items per page</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-responsive-md">
                                                <thead className='mx-auto text-center'>
                                                    <tr>
                                                        <th>S.no</th>
                                                        <th>Date</th>
                                                        <th>Core</th>
                                                        <th>Stage</th>
                                                        <th>Score</th>
                                                        <th>Result</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='mx-auto text-center'>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>05-11-2024</td>
                                                        <td>UPSC</td>
                                                        <td>Mains</td>
                                                        <td>50</td>
                                                        <td><span className="badge bg-success">Pass</span></td>
                                                        <td>
                                                            <button className="btn bg-info-light">
                                                                <i className="fas fa-pen-to-square"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>27-10-2024</td>
                                                        <td>UPSC</td>
                                                        <td>Mains</td>
                                                        <td>20</td>
                                                        <td><span className="badge bg-danger">Fail</span></td>
                                                        <td>
                                                            <button className="btn bg-info-light">
                                                                <i className="fas fa-pen-to-square"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>26-10-2024</td>
                                                        <td>UPSC</td>
                                                        <td>Prelims</td>
                                                        <td>90</td>
                                                        <td><span className="badge bg-success">Pass</span></td>
                                                        <td>
                                                            <button className="btn bg-info-light">
                                                                <i className="fas fa-pen-to-square"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>25-10-2024</td>
                                                        <td>UPSC</td>
                                                        <td>Prelims</td>
                                                        <td>60</td>
                                                        <td><span className="badge bg-success">Pass</span></td>
                                                        <td>
                                                            <button className="btn bg-info-light">
                                                                <i className="fas fa-pen-to-square"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>25-10-2024</td>
                                                        <td>UPSC</td>
                                                        <td>Prelims</td>
                                                        <td>60</td>
                                                        <td><span className="badge bg-success">Pass</span></td>
                                                        <td>
                                                            <button className="btn bg-info-light">
                                                                <i className="fas fa-pen-to-square"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mt-3">
                                            <div className='text-dark'>
                                                Showing <b className='text-secondary'>5</b> of <b className='text-secondary'>17</b> entries
                                            </div>
                                            <div className='text-dark'>
                                                <span className="me-4">Page <b className='text-secondary'>1</b> of  <b className='text-secondary'>3</b></span>
                                                <button className='btn btn-xs btn-info light'>
                                                    <i className='fas fa-chevron-left'></i></button>
                                                <button className='btn btn-xs btn-info light ms-2'>
                                                    <i className='fas fa-chevron-right'></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default StudentProfile;