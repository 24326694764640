import React, { useEffect, useState, useRef } from 'react'
import SideBar from '../../Layouts/SideBar'
import NavBar from '../../Layouts/NavBar'
import Header from '../../Layouts/Header'
import Footer from '../../Layouts/Footer'
import { apiURL } from '../../CommonConfig/BaseUrl';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router-dom";
import knowledgeImg from '../../imgs/knowledge.png'
import AssesmentImg from '../../imgs/assessment.png'


function BotSummary() {


  const [knowledges, setKnowledges] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const navigate = useNavigate();
  const [color, setColor] = useState('#ffffff');
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const [botname, setBotname] = useState("");
  const [fontsize, setFontsize] = useState(14);
  const [fontfamily, setFontfamily] = useState("Arial");
  const [welcomemsg, setWelcomemsg] = useState("Welcome");
  const [unknownmsg, setUnknownmsg] = useState("I don't understand");
  const [disclimermsg, setDisclimermsg] = useState("This is a general disclaimer.");
  const [activeTab, setActiveTab] = useState('navpills2-11');
  const [knowledgeHubId, setKnowledgeHubId] = useState(0);
  const [pdfData, setPdfData] = useState([]);
  const [options, setOptions] = useState([]);
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [assessmentHubId, setAssessmentHubId] = useState(0);
  const [studentBasedOnTeam, setStudentBasedOnTeam] = useState([]);
  const [studentBasedOnBatch, setStudentBasedOnBatch] = useState([]);
  const [filesData, setFilesData] = useState([]);
  const [studentIds, setStudentIds] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [studentCount, setStudentCount] = useState(0);;
  const [cardId, setCardId] = useState(0);
  const [teamId, setTeamId] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [assessId, setAssessId] = useState(0);
  const [teamNameAss, setTeamNameAss] = useState("");
  const [studentCountAss, setStudentCountAss] = useState(0);;
  const [teamIdAss, setTeamIdAss] = useState(0);
  const [fileUploaded, setFileUploaded] = useState(false);

  useEffect(() => {
    fetchData();
    getData();
  }, []);

  const fetchData = async () => {
    axios.get(`${apiURL}/get_all_knowledge`)
      .then((response) => {
        if (response.data && response.data.data) {
          setKnowledges(response.data.data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const getData = async () => {
    axios.get(`${apiURL}/get_all_interview`)
      .then((response) => {
        if (response.data && response.data.data) {
          setAssessments(response.data.data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }

  const handleColorChange = (e) => {
    setColor(e.target.value);
  };

  const fetchCombinedData = async () => {
    const response = await fetch('https://api.syncviz.ai/vbot_getall_files');
    const data = await response.json();
    setPdfData(data.db_vbot_knowledge);
    console.log(data, pdfData, "pdfdata");
  };

  const handleDownloadKnowledge = async (fileId) => {
    try {
      // Send a GET request to the download API
      const response = await axios.get(`https://api.syncviz.ai/Filedownload/${fileId}`, {
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `file_${fileId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
      alert('An error occurred while downloading the file. Please try again.');
    }
  };

  const handleDeleteKnowledge = async (fileId, file_location) => {
    console.log(fileId, "file id");

    const confirmDelete = window.confirm(`Are you sure you want to delete the file with ID: ${fileId}?`);
    if (!confirmDelete) return;

    try {
      const response = await axios.delete(`https://api.syncviz.ai/deleteuplodfiles/?file_id=${fileId}&file_location=${file_location}`);

      if (response.status === 200) {
        alert('File deleted successfully.');
        setPdfData((prevData) => prevData.filter(file => file.file_id !== fileId));
      } else {
        alert('Failed to delete the file.');
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      alert('An error occurred while deleting the file. Please try again.');
    }
  };

  // Handle hex code input change
  const handleHexInputChange = (e) => {
    const hex = e.target.value;
    if (/^#([0-9A-F]{3}){1,2}$/i.test(hex)) {
      setColor(hex);
    } else {
      setColor('#ffffff');
    }
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setUploadedFiles(files);
    if (e.target.files.length > 0) {
      setFileUploaded(true); // Update the state when a file is uploaded
    }
  };

  const handleSubmitKnowledge = async () => {
    var Botname = botname;
    var Botcolor = color;
    var Fontsize = fontsize;
    var Fontfamily = fontfamily;
    var Welcomemsg = welcomemsg;
    var Unknownmsg = unknownmsg;
    var Disclimermsg = disclimermsg;

    if (!Botname || !Botcolor || !Fontsize || !Fontfamily || !Welcomemsg || !Unknownmsg || !Disclimermsg) {
      showAlert('Please fill in all fields before submitting', 'error');
      return;
    }

    var payload = {
      "bot_name": Botname,
      "bot_color": Botcolor,
      "font_size": Fontsize,
      "font_family": Fontfamily,
      "welcome_message": Welcomemsg,
      "unknown_response": Unknownmsg,
      "disclimer_message": Disclimermsg,
      "created_by": 0,
    };

    try {

      const response = await axios.post(`${apiURL}/create_knowledge`, payload);
      if (response.data.id !== null) {
        setKnowledgeHubId(response.data.id)
        showAlert('Knowledge bot configuration added successfully', 'success');
        fetchCombinedData();
        setActiveTab("navpills2-21");

      } else {
        showAlert('Knowledge bot configuration added failed', 'error');
      }

    } catch (error) {
      console.error('Error making POST request:', error);
      showAlert('Error making POST request', 'error');
    }

  };

  const handleSubmitAssessment = async () => {
    var Botname = botname;
    var Botcolor = color;
    var Fontsize = fontsize;
    var Fontfamily = fontfamily;
    var Welcomemsg = welcomemsg;
    var Unknownmsg = unknownmsg;
    var Disclimermsg = disclimermsg;

    if (!Botname || !Botcolor || !Fontsize || !Fontfamily || !Welcomemsg || !Unknownmsg || !Disclimermsg) {
      showAlert('Please fill in all fields before submitting', 'error');
      return;
    }

    var payload = {
      "bot_name": Botname,
      "bot_color": Botcolor,
      "font_size": Fontsize,
      "font_family": Fontfamily,
      "welcome_message": Welcomemsg,
      "unknown_response": Unknownmsg,
      "disclimer_message": Disclimermsg,
      "created_by": 0,
    };

    try {

      const response = await axios.post(`${apiURL}/create_interview`, payload);
      if (response.data.id !== null) {
        setAssessmentHubId(response.data.id)
        showAlert('Assessment bot configuration added successfully', 'success');
        getData();
        setActiveTab("navpills2-21");

      } else {
        showAlert('Assessment bot configuration added failed', 'error');
      }

    } catch (error) {
      console.error('Error making POST request:', error);
      showAlert('Error making POST request', 'error');
    }

  };

  const uploadKnowlegde = async (hub_id) => {
    debugger
    if (uploadedFiles.length === 0) {
      showAlert('Please select at least one file to upload', 'warning');
      return;
    }

    const formData = new FormData();
    formData.append('hub_id', knowledgeHubId);
    uploadedFiles.forEach((file) => {
      const mimeType = file.type || 'application/pdf';
      formData.append('file', file, file.name);
    });

    try {
      // const response = await axios.post(`${apiURL}/fileuploads`, formData, {
      const response = await axios.post(`https://api.syncviz.ai/fileuploads?hub_id=${knowledgeHubId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      debugger
      if (response.status === 200) {
        console.log(response);
        if (response.data.file_url !== "") {
          showAlert('File(s) uploaded successfully!', 'success');
          setActiveTab('navpills2-22');
          fetchCombinedData();

        } else {
          showAlert('File upload failed', 'error');
        }

      } else {
        showAlert('File upload failed', 'error');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      Swal.fire({
        title: 'Error uploading file',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#2EA87E',
      });
    }
  };

  const showAlert = (message, type) => {
    Swal.fire({
      title: message,
      icon: type,
      confirmButtonText: 'OK',
      confirmButtonColor: '#2EA87E',
    });
  };

  useEffect(() => {
    fetchData();
    fetchData1();
  }, []);

  useEffect(() => {
    fetchCombinedData();
  }, []);

  const fetchData1 = async () => {
    try {
      const response = await fetch(`${apiURL}/get_all_team`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });

      const result = await response.json();

      if (result.message === "Success") {
        const formattedOptions = result.data.map((team) => ({
          value: team.id,
          label: team.Name,
        }));
        setOptions(formattedOptions);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const UpdateTeamData = async () => {
    if (team == null) {
      showAlert('Please select team', 'warning');
      return;
    }
    try {
      const response = await axios.put(`${apiURL}/update_knowledge_team/${knowledgeHubId}/${team}`, {
        headers: {
          'Accept': 'application/json'
        }
      });
      if (response.status === 200) {
        showAlert('Team Assigned successfully!', 'success');
      } else {
        showAlert('Team Assigned failed', 'error');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleResetHub = () => {
    setIsLoading(true);
    setTimer(60);


    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(countdown);
          setIsLoading(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);


    axios
      .post("https://api.syncviz.ai/scriptfile", {}, {
        headers: {
          accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Bot restart response:", response);
      })
      .catch((error) => {
        console.error("Error restarting bot:", error);
      })
      .finally(() => {

        console.log("API call completed.");
      });
  };

  const fetchStudents = async () => {
    try {
      const response = await axios.get(`${apiURL}/get_all_Students`);

      if (response.status === 200 && response.data && response.data.data) {
        const sortedData = response.data.data.sort((a, b) => a.id - b.id);
        setStudents(sortedData);

      } else {

      }
    } catch (error) {
      console.error('Error fetching students:', error);

    } finally {
    }
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to page 1 when searching
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to page 1 when changing items per page
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const filteredStudents = studentBasedOnTeam.filter(
    (student) =>
      student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.studentid.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.number.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.emailid.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalItems = filteredStudents.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const paginatedStudents = filteredStudents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const uploadAssesment = async () => {
    if (uploadedFiles.length === 0) {
      showAlert('Please select at least one file to upload', 'warning');
      return;
    }

    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      const mimeType = file.type || 'application/pdf';
      formData.append('file', file, file.name);
    });

    setLoading(true); // Start loading

    try {
      const response = await axios.post(`${apiURL}/generate_qa/${assessmentHubId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        if (response.data.file_url !== "") {
          showAlert('File(s) uploaded successfully!', 'success');
        } else {
          showAlert('File upload failed', 'error');
        }
      } else {
        showAlert('File upload failed', 'error');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      Swal.fire({
        title: 'Error uploading file',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#2EA87E',
      });
    } finally {
      setLoading(false); // Stop loading after upload completion or error
    }
  };

  const UpdateTeamDataAssessment = async () => {
    if (team == null) {
      showAlert('Please select team', 'warning');
      return;
    }
    try {
      const response = await axios.put(`${apiURL}/update_assessment_team/${assessmentHubId}/${team}`, {
        headers: {
          'Accept': 'application/json'
        }
      });
      if (response.status === 200) {
        showAlert('Team Assigned successfully!', 'success');
      } else {
        showAlert('Team Assigned failed', 'error');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const showAllStudentsBasedOnTeam = async (teamId) => {
    try {
      const response = await axios.get(`${apiURL}/get_team_by_id/${teamId}`);

      if (response.status === 200 && response.data && response.data.data) {
        const studentsListBasedOnTeam = response.data.data.students
        setStudentBasedOnTeam(studentsListBasedOnTeam)
      } else {
        console.error('Failed to load students');
      }
    } catch (error) {
      console.error('Error fetching students:', error);
    } finally {
    }
  };

  const showAllStudentsBasedOnBatch = async (teamIdAss) => {
    try {
      const response = await axios.get(`${apiURL}/get_team_by_id/${teamIdAss}`);

      if (response.status === 200 && response.data && response.data.data) {
        const studentsListBasedOnTeam = response.data.data.students
        setStudentBasedOnBatch(studentsListBasedOnTeam)
      } else {
        console.error('Failed to load students');
      }
    } catch (error) {
      console.error('Error fetching students:', error);
    } finally {
    }
  };

  const fetchPdf = async (id) => {
    console.log('clicked knowledge id', id);
    setCardId(id);
    try {
      const [filesResponse, studentDataResponse] = await Promise.all([
        axios.get(`https://api.syncviz.ai/files?hub_id=${id}`),
        axios.get(`${apiURL}/get_hub_student_data/${id}`)
      ]);

      if (filesResponse.data.files && filesResponse.data.files.length > 0) {
        setFilesData(filesResponse.data.files);
      } else {
        setFilesData([{ message: "No files found." }]);
      }

      const { team } = studentDataResponse.data;
      console.log("studentDataResponsestudentDataResponse", studentDataResponse);
      setTeamName(team.team_name || team.Name);
      setStudentIds(team.students);
      setStudentCount(team.students.length);
      setTeamId(team.id)
    } catch (error) {
      console.error("Error fetching data:", error);
      setFilesData([{ message: "Error fetching files" }]);
    }
  };

  const fetchAssess = async (id) => {
    console.log('clicked assess id', id);
    setAssessId(id);
    try {
      const [studentDataResponse] = await Promise.all([
        axios.get(`${apiURL}/get_assessment_student_data/${id}`)
      ]);


      const { team } = studentDataResponse.data;
      console.log("studentDataResponsestudentDataResponseAssess", studentDataResponse);
      setTeamNameAss(team.team_name || team.Name);
      setStudentCountAss(team.students.length);
      setTeamIdAss(team.id)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileAssess, setSelectedFileAssess] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleFileToHub = async (cardId) => {
    if (!selectedFile) {
      Swal.fire("Warning", "Please select a file to upload.", "warning");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(
        `https://api.syncviz.ai/fileuploads?hub_id=${cardId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );

      const successMessage = response.data.message || "File uploaded successfully!";

      Swal.fire({
        title: "Success",
        text: successMessage,
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        fileInputRef.current.value = "";
        navigate("/BotSummary");
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      Swal.fire("Error", "Error uploading file.", "error");
    }
  };

  const handleFileChangeAssess = (event) => {
    const file = event.target.files[0];
    setSelectedFileAssess(file);
  };

  const handleFileToAssess = async (assessId) => {
    if (!selectedFileAssess) {
      Swal.fire("Warning", "Please select a file to upload.", "warning");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(
        `${apiURL}/upload_Assessment_document?id=${assessId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );

      const successMessage = response.data.message || "File uploaded successfully!";

      Swal.fire({
        title: "Success",
        text: successMessage,
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        fileInputRef.current.value = "";
        navigate("/BotSummary");
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      Swal.fire("Error", "Error uploading file.", "error");
    }
  };

  const handleViewMore = () => {
    setShowAll(true);
  };

  const handleViewLess = () => {
    setShowAll(false);
  };



  const visibleKnowledges = showAll ? knowledges : knowledges.slice(0, 6);

  const [showAllAss, setShowAllAss] = useState(false);

  const visibleAssessments = showAllAss ? assessments : assessments.slice(0, 6);

  return (
    <div>
      <div id="main-wrapper">
        <NavBar />
        <Header />
        <SideBar />
        <div class="content-body default-height">
          <div class="container-fluid">
            <div className="row d-flex justify-content-center">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">HUBS</h4>
                  </div>
                  <div class="card-body">
                    <div class="card-tabs d-flex justify-content-between align-items-center">
                      <ul class="nav nav-tabs d-inline-flex mb-4" role="tablist">
                        <li class="nav-item" role="presentation">
                          <a href="#navpills2-11" class="nav-link active" data-bs-toggle="tab" aria-expanded="false" aria-selected="true" role="tab">Knowledge Hub Summary</a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a href="#navpills2-21" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Assessment Hub Summary</a>
                        </li>
                      </ul>

                    </div>

                    <div class="tab-content">
                      <div id="navpills2-11" class="tab-pane active show" role="tabpanel">
                        <div class="row mb-4">
                          <div class="d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-primary mx-2"
                              data-bs-toggle="modal"
                              data-bs-target=".bd-example-modal-lg1">
                              Create Knowledge Hub
                            </button>
                            <button
                              className={`btn ${isLoading ? "btn-info" : "btn-info"}`}
                              onClick={handleResetHub}
                              style={{
                                marginRight: "30px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                minWidth: "180px",
                              }}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  <span style={{ marginLeft: "10px" }}>
                                    Synchronizing... {timer > 0 ? `${timer}s` : ""}
                                  </span>
                                </>
                              ) : (
                                "Synchronize Hub"
                              )}
                            </button>
                          </div>
                        </div>
                        <div class="row">
                          <div className="row">
                            {visibleKnowledges.length > 0 ? (
                              visibleKnowledges.map((knowledge, index) => (
                                <div className="col-xl-6 col-md-12" key={index}>
                                  <div
                                    className="widget-stat card"
                                    id="your-card-id"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => fetchPdf(knowledge.id)}
                                    data-bs-toggle="modal"
                                    data-bs-target=".bd-example-modal-lg10"
                                  >
                                    <div className="card-body p-4">
                                      <div className="media ai-icon">
                                        <span className="me-3 bgl-primary text-primary">
                                          <img src={knowledgeImg} style={{ width: '100%' }}></img>
                                        </span>
                                        <div className="media-body align-items-center">
                                          <h5 className="text-uppercase">{knowledge.bot_name}</h5>
                                          <p className="m-0">User Count / Batch Count</p>
                                          <h4 className="mb-0 mx-4">{knowledge.team || 40}</h4>
                                          <span className="badge badge-primary ms-4">{knowledge.team || 5}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal fade bd-example-modal-lg10" tabindex="-1" data-bs-backdrop="static" style={{ display: 'none' }} aria-hidden="true">
                                    <div class="modal-dialog modal-lg">
                                      <div class="modal-content">
                                        <div class="modal-header">
                                          <h5 class="modal-title">Hub Details</h5>
                                          <button type="button" class="btn-close" data-bs-dismiss="modal">
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <div class="card-body">
                                            <div class="card-tabs">
                                              <ul class="nav nav-tabs d-inline-flex mb-4" role="tablist">
                                                <li class=" nav-item" role="presentation">
                                                  <a href="#navpills2-100" class="nav-link active" data-bs-toggle="tab" aria-expanded="false" aria-selected="true" role="tab">Knowledge</a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                  <a href="#navpills2-200" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Batch</a>
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="tab-content">
                                              <div id="navpills2-100" className="tab-pane active show" role="tabpanel">

                                                <div className="row">
                                                  <div className="col-12">
                                                    <hr />
                                                    <div className="table-responsive">
                                                      <span className='d-flex align-items-center justify-content-between'>
                                                        <input
                                                          type="file"
                                                          onChange={handleFileChange}
                                                          accept=".pdf"
                                                          className="mb-3 border rounded p-2"
                                                          ref={fileInputRef}
                                                        />
                                                        <button className='btn btn-primary mb-3' onClick={() => handleFileToHub(cardId)}>
                                                          Upload Files to Hub
                                                        </button>
                                                      </span>
                                                      <table className="table bordered">
                                                        <thead className="thead">
                                                          <tr>
                                                            <th>S.no</th>
                                                            <th>File ID</th>
                                                            <th>File Name</th>
                                                            <th>Uploaded At</th>
                                                            {/* <th>Action</th> */}
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {Array.isArray(filesData) && filesData.length > 0 ? (
                                                            filesData.map((file, index) => (
                                                              <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{file.file_id}</td>
                                                                <td>{file.file_name}</td>
                                                                <td>{new Date(file.created_at).toLocaleString()}</td>
                                                                {/* <td>
                                                                <button className="btn bg-info-light">
                                                                  <i className="fas fa-pen-to-square"></i>
                                                                </button>
                                                              </td> */}
                                                              </tr>
                                                            ))
                                                          ) : (
                                                            <td colSpan="4" className="text-center">
                                                              {filesData[0]?.message || "No files found."}
                                                            </td>
                                                          )}
                                                        </tbody>
                                                      </table>

                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div id="navpills2-200" class="tab-pane" role="tabpanel">
                                                <div class="row">
                                                  <div className="table-responsive">
                                                    <table className="table table-responsive-md">
                                                      <thead className="mx-auto text-center">
                                                        <tr>
                                                          <th>Batch Name</th>
                                                          <th>Student Count</th>
                                                          <th>View</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody className="mx-auto text-center">
                                                        <tr>
                                                          <td>{teamName}</td>
                                                          <td>{studentCount}</td>
                                                          <td>
                                                            <button
                                                              type="button"
                                                              className="btn btn-xs btn-success light"
                                                              data-bs-toggle="modal"
                                                              data-bs-target=".std-view-modal-knowledge"
                                                              onClick={() => showAllStudentsBasedOnTeam(teamId)}
                                                            >
                                                              View
                                                            </button>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-danger light" data-bs-dismiss="modal">Close</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="col-12">
                                <p>No Knowledges available</p>
                              </div>
                            )}
                          </div>
                          {/* View More/Less Button */}
                          {knowledges.length > 6 && (
                            <div className="text-center mt-4">
                              {!showAll ? (
                                <button className="btn btn-primary" onClick={handleViewMore}>
                                  View More
                                </button>
                              ) : (
                                <button className="btn btn-secondary" onClick={handleViewLess}>
                                  View Less
                                </button>
                              )}
                            </div>
                          )}
                        </div>

                        {/* MODAL  TO  VIEW  STUDENT  DETAILS */}
                        <div class="modal fade std-view-modal-knowledge" tabindex="-1" style={{ display: 'none' }} data-bs-backdrop="static" aria-hidden="true">
                          <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h3 class="modal-title text-primary">Student Details</h3>
                                <button type="button" class="btn-close" data-bs-dismiss="modal">
                                </button>
                              </div>
                              <div className="modal-body">
                                {/* Search and Items per page */}
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                  <div className="col-xl-6">
                                    <div className="input-group mb-2">
                                      <div className="input-group-text">
                                        <i className="fas fa-search"></i>
                                      </div>
                                      <input
                                        type="text"
                                        className="form-control wide"
                                        placeholder="Search by Name / Student ID / Mobile / E-Mail ID"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xl-3">
                                    <select
                                      className="form-control wide"
                                      value={itemsPerPage}
                                      onChange={handleItemsPerPageChange}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <option value="5">5 - items per page</option>
                                      <option value="10">10 - items per page</option>
                                      <option value="15">15 - items per page</option>
                                      <option value="20">20 - items per page</option>
                                    </select>
                                  </div>
                                </div>

                                {/* Students Table */}
                                <div className="table-responsive">
                                  <table className="table table-responsive-md">
                                    <thead className="mx-auto text-center">
                                      <tr>
                                        <th>S.No</th>
                                        <th>Student Name</th>
                                        <th>Student ID</th>
                                        <th>Email ID</th>
                                        <th>Mobile Number</th>
                                        <th>Department</th>
                                      </tr>
                                    </thead>
                                    <tbody className="mx-auto text-center">
                                      {paginatedStudents.map((student, index) => (
                                        <tr key={index}>
                                          <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                          <td>{student.name}</td>
                                          <td>{student.studentid}</td>
                                          <td>{student.emailid}</td>
                                          <td>{student.number}</td>
                                          <td>{student.department}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>

                                {/* Pagination and Entries Info */}
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                  <div className="text-dark">
                                    Showing <b className="text-primary">{paginatedStudents.length}</b> of{" "}
                                    <b className="text-primary">{totalItems}</b> entries
                                  </div>
                                  <div className="text-dark">
                                    <span className="me-4">
                                      Page <b className="text-primary">{currentPage}</b> of{" "}
                                      <b className="text-primary">{totalPages}</b>
                                    </span>
                                    <button
                                      className="btn btn-xs btn-primary"
                                      onClick={() => handlePageChange(currentPage - 1)}
                                      disabled={currentPage === 1}
                                    >
                                      <i className="fas fa-chevron-left"></i>
                                    </button>
                                    <button
                                      className="btn btn-xs btn-primary ms-2"
                                      onClick={() => handlePageChange(currentPage + 1)}
                                      disabled={currentPage === totalPages || totalPages === 0}
                                    >
                                      <i className="fas fa-chevron-right"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="modal fade bd-example-modal-lg1" tabindex="-1" data-bs-backdrop="static" aria-hidden="true" style={{ display: 'none' }}>
                          <div class="modal-dialog modal-lg" style={{ '--bs-modal-width': '1000px' }}>
                            <div class=" modal-content col-xl-12">
                              <div class="">
                                <div class="card-header">
                                  <h4 class="card-title">Knowledge Hub Configure</h4>
                                  <button type="button" class="btn-close" data-bs-dismiss="modal">
                                  </button>
                                </div>
                                <div class="card-body">
                                  <div class="card-tabs d-flex flex-wrap justify-content-between align-items-center mb-3">
                                    <span>
                                      <ul class="nav nav-tabs d-inline-flex" role="tablist">
                                        <li class=" nav-item" role="presentation">
                                          <button className={`nav-link ${activeTab === "navpills2-11" ? "active" : ""}`} onClick={() => setActiveTab("navpills2-11")} role="tab">Hub Configure</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                          <button className={`nav-link ${activeTab === "navpills2-21" ? "active" : ""}`} onClick={() => setActiveTab("navpills2-21")} role="tab">Upload Knowledge</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                          <button className={`nav-link ${activeTab === "navpills2-22" ? "active" : ""}`} onClick={() => setActiveTab("navpills2-22")} role="tab">Assign Batch</button>
                                        </li>
                                      </ul>
                                    </span>
                                  </div>
                                  <div class="tab-content">
                                    {activeTab === 'navpills2-11' && (
                                      <div id="navpills2-11" class="tab-pane active show" role="tabpanel">
                                        <div class="card-body">
                                          <div class="basic-form">
                                            <form>
                                              <div class="row">
                                                <div class="mb-3 col-md-6">
                                                  <label class="form-label">Hub Name</label>
                                                  <input type="text" class="form-control" placeholder="Enter Hub Name" value={botname} onChange={(e) => setBotname(e.target.value)} />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                  <label className="form-label">Hub Color</label>
                                                  <div className="input-group">
                                                    <input type="color" className="form-control" value={color} onChange={handleColorChange} />
                                                    <input type="text" className="form-control w-50" placeholder="#ffffff" value={color} onChange={handleHexInputChange} />
                                                  </div>

                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="mb-3 col-md-6">
                                                  <label class="form-label">Font Size</label>
                                                  <input type="number" class="form-control" placeholder="Font Size" value={fontsize} onChange={(e) => setFontsize(e.target.value)} />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                  <label className="form-label">Font Family</label>
                                                  <select
                                                    className="form-control"
                                                    value={fontfamily}
                                                    onChange={(e) => setFontfamily(e.target.value)}
                                                  >
                                                    <option value="Arial">Arial</option>
                                                    <option value="Verdana">Verdana</option>
                                                    <option value="Times New Roman">Times New Roman</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Courier New">Courier New</option>
                                                    <option value="Tahoma">Tahoma</option>
                                                    <option value="Trebuchet MS">Trebuchet MS</option>
                                                  </select>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="mb-3 col-md-12">
                                                  <label class="form-label">Hub Welcome Message</label>
                                                  <select
                                                    class="form-control"
                                                    value={welcomemsg}
                                                    onChange={(e) => setWelcomemsg(e.target.value)}
                                                  >
                                                    <option value="Welcome">Welcome</option>
                                                    <option value="Hello">Hello</option>
                                                    <option value="Greetings">Greetings</option>
                                                  </select>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="mb-3 col-md-12">
                                                  <label class="form-label">Hub Unknown Response</label>
                                                  <select
                                                    class="form-control"
                                                    value={unknownmsg}
                                                    onChange={(e) => setUnknownmsg(e.target.value)}
                                                  >
                                                    <option value="I don't understand">I don't understand</option>
                                                    <option value="Can you rephrase?">Can you rephrase?</option>
                                                    <option value="Sorry, I didn’t get that">Sorry, I didn’t get that</option>
                                                  </select>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="mb-3 col-md-12">
                                                  <label class="form-label">Hub Disclaimer Message</label>
                                                  <select
                                                    class="form-control"
                                                    value={disclimermsg}
                                                    onChange={(e) => setDisclimermsg(e.target.value)}
                                                  >
                                                    <option value="This is a general disclaimer.">This is a general disclaimer.</option>
                                                    <option value="Please verify all information.">Please verify all information.</option>
                                                    <option value="No guarantees are provided.">No guarantees are provided.</option>
                                                  </select>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                          <div className='mt-2 text-center'>
                                            <button type="button" class="btn btn-primary" onClick={handleSubmitKnowledge}>Next</button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {activeTab === 'navpills2-21' && (
                                      <div id="navpills2-21" class="tab-pane active show" role="tabpanel">
                                        <div className="card-body d-flex justify-content-center">
                                          <div className="col-xl-6 col-xxl-6">
                                            <div className="d-flex justify-content-center mb-2">
                                              <h4 className="card-title">Upload your Documents</h4>
                                            </div>
                                            <div className="col-lg-12 px-5">
                                              <input
                                                className="form-control"
                                                type="file"
                                                id="fileUpload"
                                                multiple
                                                onChange={handleFileUpload}
                                                style={{ display: 'none' }}
                                              />
                                              <label
                                                htmlFor="fileUpload"
                                                className="drag-drop-area bg-success-light d-flex flex-column justify-content-between"
                                                style={{
                                                  border: '0.2rem dashed #cccccc',
                                                  borderRadius: '1vh',
                                                  padding: '12vh',
                                                  paddingBottom: '4vh',
                                                  textAlign: 'center',
                                                  cursor: 'pointer',
                                                  minHeight: '200px', // Ensures label is tall enough to hold content
                                                }}
                                              >
                                                {fileUploaded ? (
                                                  <div>
                                                    <i className="fas fa-upload"></i>
                                                    <p>Your file has been uploaded!</p>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <i className="fas fa-upload"></i>
                                                    <p>Drag & Drop your file here or click to upload</p>
                                                  </div>
                                                )}
                                                <div style={{ marginTop: '40px' }}>
                                                  <h6>Instructions</h6>
                                                  <ul style={{ paddingLeft: '1rem', textAlign: 'center' }}>
                                                    <li>Uploaded files must not exceed 20 pages.</li>
                                                    <li>Please ensure that the file size is less than 50MB.</li>
                                                  </ul>
                                                </div>
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        <div className='mt-2 text-center'>
                                          <button type="button" class="btn btn-primary" onClick={uploadKnowlegde}>Upload</button>
                                        </div>

                                        {/* <div class="row">
                                          <div className="d-flex justify-content-center">
                                            <h4 className="card-title mb-3">Existing Files</h4>
                                          </div>
                                          <hr />
                                          {pdfData?.map((file) => (
                                            <div className="card col-3" key={file.file_id}>
                                              <div className="card-body text-center ai-icon text-primary">
                                                <svg id="pdf-icon" className="my-1" viewBox="0 0 24 24" width="60"
                                                  height="60" stroke="currentColor" fill="none">
                                                  <path d="M6 2H16L20 6V22H4V2H6Z" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                                  <path d="M16 2V10H20" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                                  <path d="M8 12H16" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                                  <path d="M8 16H12" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                                <h5 className="my-1" title={file.file_name}>
                                                  {file.file_name.length > 20 ? `${file.file_name.substring(0, 10)}...` : file.file_name}
                                                </h5>
                                                <a href="javascript:void(0);" className="btn btn-primary btn-xs my-1 px-3" onClick={() => handleDownloadKnowledge(file.file_id)}>
                                                  Download
                                                </a>
                                                <button
                                                  type="button"
                                                  className="btn btn-danger btn-xs my-1 mx-3"
                                                  onClick={() => handleDeleteKnowledge(file.file_id, file.file_location)} // Delete the file
                                                >
                                                  Delete
                                                </button>
                                              </div>
                                            </div>
                                          ))}
                                        </div> */}
                                      </div>
                                    )}
                                    {activeTab === 'navpills2-22' && (
                                      <div id="navpills2-22" class="tab-pane active show" role="tabpanel">

                                        <div className='card-body'>
                                          <div class="d-flex flex-wrap align-items-center justify-content-between">

                                            <div class="align-items-center d-flex">
                                              <div class="me-4">
                                                <label class="text-primary fw-bold ms-2 mb-0">Select Batch</label>
                                                <select class="select form-control wide mt-1" value={team} onChange={(e) => {
                                                  console.log(e.target.value);

                                                  setTeam(e.target.value)
                                                  showAllStudentsBasedOnTeam(e.target.value)
                                                }}>
                                                  <option value="">Select</option>
                                                  {options.map((option, index) => (
                                                    <option value={option.value}>{option.label}</option>
                                                  ))
                                                  }
                                                </select>
                                              </div>
                                              <div class="">
                                                <button type="button" class="btn btn-primary mt-4" onClick={UpdateTeamData}>
                                                  <i class="fas fa-add me-3"></i>Assign Batch
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card">
                                          <div className="card-body">
                                            {/* Search and Items per page */}
                                            <div className="d-flex align-items-center justify-content-between mb-4">
                                              <div className="col-xl-6">
                                                <div className="input-group mb-2">
                                                  <div className="input-group-text">
                                                    <i className="fas fa-search"></i>
                                                  </div>
                                                  <input
                                                    type="text"
                                                    className="form-control wide"
                                                    placeholder="Search by Name / Student ID / Mobile / E-Mail ID"
                                                    value={searchTerm}
                                                    onChange={handleSearch}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-xl-3">
                                                <select
                                                  className="form-control wide"
                                                  value={itemsPerPage}
                                                  onChange={handleItemsPerPageChange}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <option value="5">5 - items per page</option>
                                                  <option value="10">10 - items per page</option>
                                                  <option value="15">15 - items per page</option>
                                                  <option value="20">20 - items per page</option>
                                                </select>
                                              </div>
                                            </div>

                                            {/* Students Table */}
                                            <div className="table-responsive">
                                              <table className="table table-responsive-md">
                                                <thead className="mx-auto text-center">
                                                  <tr>
                                                    <th>S.No</th>
                                                    <th>Student Name</th>
                                                    <th>Student ID</th>
                                                    <th>Email ID</th>
                                                    <th>Mobile Number</th>
                                                    <th>Department</th>
                                                  </tr>
                                                </thead>
                                                <tbody className="mx-auto text-center">
                                                  {paginatedStudents.map((student, index) => (
                                                    <tr key={index}>
                                                      <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                      <td>{student.name}</td>
                                                      <td>{student.studentid}</td>
                                                      <td>{student.emailid}</td>
                                                      <td>{student.number}</td>
                                                      <td>{student.department}</td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                            </div>

                                            {/* Pagination and Entries Info */}
                                            <div className="d-flex justify-content-between align-items-center mt-3">
                                              <div className="text-dark">
                                                Showing <b className="text-primary">{paginatedStudents.length}</b> of{" "}
                                                <b className="text-primary">{totalItems}</b> entries
                                              </div>
                                              <div className="text-dark">
                                                <span className="me-4">
                                                  Page <b className="text-primary">{currentPage}</b> of{" "}
                                                  <b className="text-primary">{totalPages}</b>
                                                </span>
                                                <button
                                                  className="btn btn-xs btn-primary"
                                                  onClick={() => handlePageChange(currentPage - 1)}
                                                  disabled={currentPage === 1}
                                                >
                                                  <i className="fas fa-chevron-left"></i>
                                                </button>
                                                <button
                                                  className="btn btn-xs btn-primary ms-2"
                                                  onClick={() => handlePageChange(currentPage + 1)}
                                                  disabled={currentPage === totalPages || totalPages === 0}
                                                >
                                                  <i className="fas fa-chevron-right"></i>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="navpills2-21" class="tab-pane" role="tabpanel">
                        <div class="row">
                          <div class="d-flex">
                            <button type="button" class="btn btn-primary mb-4 ms-auto" data-bs-toggle="modal" data-bs-target=".bd-example-modal-lg2">Create Assesment Hub</button>
                          </div>
                        </div>
                        <div class="row">
                          {visibleAssessments.length > 0 ? (
                            visibleAssessments.map((Assessment, index) => (
                              <div class="col-xl-6 col-md-12">
                                <div class="widget-stat card" style={{ cursor: 'pointer' }} id="your-card-id" data-bs-toggle="modal"
                                  data-bs-target=".bd-example-modal-lg77" onClick={() => fetchAssess(Assessment.id)}>
                                  <div class="card-body p-4">
                                    <div class="media ai-icon">
                                      <span class="me-3 bgl-primary text-primary">
                                        <img src={AssesmentImg} style={{ width: '80%' }}></img>
                                      </span>
                                      <div class="media-body">
                                        <h5 class="text-uppercase">{Assessment.bot_name}</h5>
                                        <p className='m-0'>User Count / Team Count</p>
                                        <h4 class="mb-0 mx-4">{Assessment.team || 40}</h4>
                                        <span class="badge badge-primary ms-4">{Assessment.team || 5}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="modal fade bd-example-modal-lg77" tabindex="-1" data-bs-backdrop="static" style={{ display: 'none' }} aria-hidden="true">
                                  <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Hub Details</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal">
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <div class="card-body">
                                          <div class="card-tabs">
                                            <ul class="nav nav-tabs d-inline-flex mb-4" role="tablist">
                                              <li class=" nav-item" role="presentation">
                                                <a href="#assessment-hub-file" class="nav-link active" data-bs-toggle="tab" aria-expanded="false" aria-selected="true" role="tab">Knowledge</a>
                                              </li>
                                              <li class="nav-item" role="presentation">
                                                <a href="#assessment-hub-batch" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Batch</a>
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="tab-content">
                                            <div id="assessment-hub-file" className="tab-pane active show" role="tabpanel">
                                              <div className="row">
                                                <div className="col-12">
                                                  <hr />
                                                  <div className="table-responsive">
                                                    {/* <span className='d-flex align-items-center justify-content-between'>
                                                      <input
                                                        type="file"
                                                        onChange={handleFileChangeAssess}
                                                        accept=".pdf"
                                                        className="mb-3 border rounded p-2"
                                                        ref={fileInputRef}
                                                      />
                                                      <button className='btn btn-primary mb-3' onClick={() => handleFileToAssess(assessId)}>
                                                        Upload Files to Hub
                                                      </button>
                                                    </span> */}
                                                    <table className="table bordered">
                                                      <thead className="thead">
                                                        <tr>
                                                          <th>S.no</th>
                                                          <th>File ID</th>
                                                          <th>File Name</th>
                                                          <th>Uploaded At</th>
                                                          {/* <th>Action</th> */}
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {Array.isArray(filesData) && filesData.length > 0 ? (
                                                          filesData.map((file, index) => (
                                                            <tr key={index}>
                                                              <td>{index + 1}</td>
                                                              <td>{file.file_id}</td>
                                                              <td>{file.file_name}</td>
                                                              <td>{new Date(file.created_at).toLocaleString()}</td>
                                                              {/* <td>
                                                                <button className="btn bg-info-light">
                                                                  <i className="fas fa-pen-to-square"></i>
                                                                </button>
                                                              </td> */}
                                                            </tr>
                                                          ))
                                                        ) : (
                                                          <tr>
                                                            <td colSpan="4" className="text-center">
                                                              {filesData[0]?.message || "No files found."}
                                                            </td>
                                                          </tr>
                                                        )}
                                                      </tbody>
                                                    </table>

                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div id="assessment-hub-batch" class="tab-pane" role="tabpanel">
                                              <div class="row">
                                                <div className="table-responsive">
                                                  <table className="table table-responsive-md">
                                                    <thead className="mx-auto text-center">
                                                      <tr>
                                                        <th>Batch Name</th>
                                                        <th>Student Count</th>
                                                        <th>View</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody className="mx-auto text-center">
                                                      <tr>
                                                        <td>{teamNameAss}</td>
                                                        <td>{studentCountAss}</td>
                                                        <td>
                                                          <button
                                                            type="button"
                                                            className="btn btn-xs btn-success light"
                                                            data-bs-toggle="modal"
                                                            data-bs-target=".std-view-modal-assessment"
                                                            onClick={() => showAllStudentsBasedOnBatch(teamIdAss)}
                                                          >
                                                            View
                                                          </button>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-danger light" data-bs-dismiss="modal">Close</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6">No Assessment available</td>
                            </tr>
                          )}
                        </div>
                        {/* View More / View Less Button */}
                        {assessments.length > 6 && (
                          <div className="text-center mt-3">
                            <button
                              className="btn btn-primary"
                              onClick={() => setShowAllAss((prevState) => !prevState)}
                            >
                              {showAllAss ? "View Less" : "View More"}
                            </button>
                          </div>
                        )}
                      </div>
                      <div class="modal fade std-view-modal-assessment" tabindex="-1" style={{ display: 'none' }} data-bs-backdrop="static" aria-hidden="true">
                        <div class="modal-dialog modal-xl">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h3 class="modal-title text-primary">Student Details</h3>
                              <button type="button" class="btn-close" data-bs-dismiss="modal">
                              </button>
                            </div>
                            <div className="modal-body">
                              {/* Search and Items per page */}
                              <div className="d-flex align-items-center justify-content-between mb-4">
                                <div className="col-xl-6">
                                  <div className="input-group mb-2">
                                    <div className="input-group-text">
                                      <i className="fas fa-search"></i>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control wide"
                                      placeholder="Search by Name / Student ID / Mobile / E-Mail ID"
                                      value={searchTerm}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                </div>
                                <div className="col-xl-3">
                                  <select
                                    className="form-control wide"
                                    value={itemsPerPage}
                                    onChange={handleItemsPerPageChange}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <option value="5">5 - items per page</option>
                                    <option value="10">10 - items per page</option>
                                    <option value="15">15 - items per page</option>
                                    <option value="20">20 - items per page</option>
                                  </select>
                                </div>
                              </div>

                              {/* Students Table */}
                              <div className="table-responsive">
                                <table className="table table-responsive-md">
                                  <thead className="mx-auto text-center">
                                    <tr>
                                      <th>S.No</th>
                                      <th>Student Name</th>
                                      <th>Student ID</th>
                                      <th>Email ID</th>
                                      <th>Mobile Number</th>
                                      <th>Department</th>
                                    </tr>
                                  </thead>
                                  <tbody className="mx-auto text-center">
                                    {studentBasedOnBatch.map((student, index) => (
                                      <tr key={index}>
                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                        <td>{student.name}</td>
                                        <td>{student.studentid}</td>
                                        <td>{student.emailid}</td>
                                        <td>{student.number}</td>
                                        <td>{student.department}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>

                              {/* Pagination and Entries Info */}
                              <div className="d-flex justify-content-between align-items-center mt-3">
                                <div className="text-dark">
                                  Showing <b className="text-primary">{paginatedStudents.length}</b> of{" "}
                                  <b className="text-primary">{totalItems}</b> entries
                                </div>
                                <div className="text-dark">
                                  <span className="me-4">
                                    Page <b className="text-primary">{currentPage}</b> of{" "}
                                    <b className="text-primary">{totalPages}</b>
                                  </span>
                                  <button
                                    className="btn btn-xs btn-primary"
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                  >
                                    <i className="fas fa-chevron-left"></i>
                                  </button>
                                  <button
                                    className="btn btn-xs btn-primary ms-2"
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages || totalPages === 0}
                                  >
                                    <i className="fas fa-chevron-right"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal fade bd-example-modal-lg2" tabindex="-1" style={{ display: 'none' }} data-bs-backdrop="static" aria-hidden="true">
              <div class="modal-dialog modal-lg" style={{ '--bs-modal-width': '1000px' }}>
                <div class=" modal-content col-xl-12">
                  <div class="">
                    <div class="card-header">

                      <h4 class="card-title">Assessment Hub Configure</h4>
                      <button type="button" class="btn-close" data-bs-dismiss="modal">
                      </button>

                    </div>
                    <div class="card-body">
                      <div class="card-tabs d-flex flex-wrap justify-content-between align-items-center mb-1">
                        <span>
                          <ul class="nav nav-tabs d-inline-flex" role="tablist">
                            <li class=" nav-item" role="presentation">
                              <a href="#navpills2-33" class="nav-link active" data-bs-toggle="tab" aria-expanded="false" aria-selected="true" role="tab">Hub Configure</a>
                            </li>
                            <li class="nav-item" role="presentation">
                              <a href="#navpills2-44" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Upload Files</a>
                            </li>
                            <li class="nav-item" role="presentation">
                              <a href="#navpills2-55" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Assign Team</a>
                            </li>
                          </ul>
                        </span>
                      </div>

                      <div class="tab-content">
                        <div id="navpills2-33" class="tab-pane active show" role="tabpanel">
                          <div class="card-body">
                            <div class="basic-form">
                              <form>
                                <div class="row">
                                  <div class="mb-3 col-md-6">
                                    <label class="form-label">Hub Name</label>
                                    <input type="text" class="form-control" placeholder="Enter Hub Name" value={botname} onChange={(e) => setBotname(e.target.value)} />
                                  </div>
                                  <div className="mb-3 col-md-6">
                                    <label className="form-label">Hub Color</label>
                                    <div className="input-group">
                                      <input type="color" className="form-control" value={color} onChange={handleColorChange} />
                                      <input type="text" className="form-control w-50" placeholder="#ffffff" value={color} onChange={handleHexInputChange} />
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="mb-3 col-md-6">
                                    <label class="form-label">Font Size</label>
                                    <input type="number" class="form-control" placeholder="Font Size" value={fontsize} onChange={(e) => setFontsize(e.target.value)} />
                                  </div>
                                  <div className="mb-3 col-md-6">
                                    <label className="form-label">Font Family</label>
                                    <select
                                      className="form-control"
                                      value={fontfamily}
                                      onChange={(e) => setFontfamily(e.target.value)}
                                    >
                                      <option value="Arial">Arial</option>
                                      <option value="Verdana">Verdana</option>
                                      <option value="Times New Roman">Times New Roman</option>
                                      <option value="Georgia">Georgia</option>
                                      <option value="Courier New">Courier New</option>
                                      <option value="Tahoma">Tahoma</option>
                                      <option value="Trebuchet MS">Trebuchet MS</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="mb-3 col-md-12">
                                    <label class="form-label">Hub Welcome Message</label>
                                    <select
                                      class="form-control"
                                      value={welcomemsg}
                                      onChange={(e) => setWelcomemsg(e.target.value)}
                                    >
                                      <option value="Welcome">Welcome</option>
                                      <option value="Hello">Hello</option>
                                      <option value="Greetings">Greetings</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="mb-3 col-md-12">
                                    <label class="form-label">Hub Unknown Response</label>
                                    <select
                                      class="form-control"
                                      value={unknownmsg}
                                      onChange={(e) => setUnknownmsg(e.target.value)}
                                    >
                                      <option value="I don't understand">I don't understand</option>
                                      <option value="Can you rephrase?">Can you rephrase?</option>
                                      <option value="Sorry, I didn’t get that">Sorry, I didn’t get that</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="mb-3 col-md-12">
                                    <label class="form-label">Hub Disclaimer Message</label>
                                    <select
                                      class="form-control"
                                      value={disclimermsg}
                                      onChange={(e) => setDisclimermsg(e.target.value)}
                                    >
                                      <option value="This is a general disclaimer.">This is a general disclaimer.</option>
                                      <option value="Please verify all information.">Please verify all information.</option>
                                      <option value="No guarantees are provided.">No guarantees are provided.</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='mt-2 text-center'>
                                  <button type="button" class="btn btn-primary" onClick={handleSubmitAssessment}>Next</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div id="navpills2-44" class="tab-pane" role="tabpanel">
                          <div className="card-body d-flex justify-content-center">
                            <div className="col-xl-6 col-xxl-6">
                              <div className="d-flex justify-content-center mb-2">
                                <h4 className="card-title">Upload your Documents</h4>
                              </div>
                              <div className="col-lg-12 px-5">
                                <input
                                  className="form-control"
                                  type="file"
                                  id="fileUpload"
                                  multiple
                                  onChange={handleFileUpload}
                                  style={{ display: 'none' }}
                                />
                                <label
                                  htmlFor="fileUpload"
                                  className="drag-drop-area bg-success-light d-flex flex-column justify-content-between"
                                  style={{
                                    border: '0.2rem dashed #cccccc',
                                    borderRadius: '1vh',
                                    padding: '12vh',
                                    paddingBottom: '4vh',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    minHeight: '200px', // Ensures label is tall enough to hold content
                                  }}
                                >
                                  {fileUploaded ? (
                                    <div>
                                      <i className="fas fa-upload"></i>
                                      <p>Your file has been uploaded!</p>
                                    </div>
                                  ) : (
                                    <div>
                                      <i className="fas fa-upload"></i>
                                      <p>Drag & Drop your file here or click to upload</p>
                                    </div>
                                  )}
                                  <div style={{ marginTop: '40px' }}>
                                    <h6>Instructions</h6>
                                    <ul style={{ paddingLeft: '1rem', textAlign: 'center' }}>
                                      <li>Uploaded files must not exceed 20 pages.</li>
                                      <li>Please ensure that the file size is less than 50MB.</li>
                                    </ul>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className='mt-2 text-center'>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={uploadAssesment}
                              disabled={loading} // Disable while loading
                            >
                              {loading ? 'Uploading...' : 'Upload'} {/* Display loader text */}
                            </button>
                          </div>

                          {/* <div class="row">
                            <div className="d-flex justify-content-center">
                              <h4 className="card-title mb-3">Existing Files</h4>
                            </div>
                            <hr />
                            <div class="card col-xl mx-xl-4">
                              <div class="card-body text-center ai-icon text-primary">
                                <svg
                                  id="pdf-icon"
                                  className="my-1"
                                  viewBox="0 0 24 24"
                                  width="60"
                                  height="60"
                                  stroke="currentColor"
                                  fill="none"
                                >
                                  <path d="M6 2H16L20 6V22H4V2H6Z" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path d="M16 2V10H20" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path d="M8 12H16" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path d="M8 16H12" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                                <h5 class="my-1">Indian Politics New.pdf</h5>
                                <a href="javascript:void(0);" class="btn btn-primary btn-xs my-1 px-3">
                                  Download
                                </a>
                                <button type="button" class="btn btn-danger btn-xs my-1 mx-3" onclick="handleButtonClick()">
                                  Delete
                                </button>
                              </div>
                            </div>
                            <div class="card col-xl mx-xl-4">
                              <div class="card-body text-center ai-icon text-primary">
                                <svg
                                  id="pdf-icon"
                                  className="my-1"
                                  viewBox="0 0 24 24"
                                  width="60"
                                  height="60"
                                  stroke="currentColor"
                                  fill="none"
                                >
                                  <path d="M6 2H16L20 6V22H4V2H6Z" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path d="M16 2V10H20" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path d="M8 12H16" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path d="M8 16H12" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                                <h5 class="my-1">Economic Survey.pdf</h5>
                                <a href="javascript:void(0);" class="btn btn-primary btn-xs my-1 px-3">
                                  Download
                                </a>
                                <button type="button" class="btn btn-danger btn-xs my-1 mx-3" onclick="handleButtonClick()">
                                  Delete
                                </button>
                              </div>
                            </div>
                            <div class="card col-xl mx-xl-4">
                              <div class="card-body text-center ai-icon text-primary">
                                <svg
                                  id="pdf-icon"
                                  className="my-1"
                                  viewBox="0 0 24 24"
                                  width="60"
                                  height="60"
                                  stroke="currentColor"
                                  fill="none"
                                >
                                  <path d="M6 2H16L20 6V22H4V2H6Z" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path d="M16 2V10H20" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path d="M8 12H16" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path d="M8 16H12" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                                <h5 class="my-1">Ethics.pdf</h5>
                                <a href="javascript:void(0);" class="btn btn-primary btn-xs my-1 px-3">
                                  Download
                                </a>
                                <button type="button" class="btn btn-danger btn-xs my-1 mx-3" onclick="handleButtonClick()">
                                  Delete
                                </button>
                              </div>
                            </div>
                            <div class="card col-xl mx-xl-4">
                              <div class="card-body text-center ai-icon text-primary">
                                <svg
                                  id="pdf-icon"
                                  className="my-1"
                                  viewBox="0 0 24 24"
                                  width="60"
                                  height="60"
                                  stroke="currentColor"
                                  fill="none"
                                >
                                  <path d="M6 2H16L20 6V22H4V2H6Z" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path d="M16 2V10H20" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path d="M8 12H16" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path d="M8 16H12" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                                <h5 class="my-1">Current affairs New.pdf</h5>
                                <a href="javascript:void(0);" class="btn btn-primary btn-xs my-1 px-3">
                                  Download
                                </a>
                                <button type="button" class="btn btn-danger btn-xs my-1 mx-3" onclick="handleButtonClick()">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div id="navpills2-55" class="tab-pane" role="tabpanel">
                          <div className='card-body'>
                            <div class="d-flex flex-wrap align-items-center justify-content-between">

                              <div class="align-items-center d-flex">
                                <div class="me-4">
                                  <label class="text-primary fw-bold ms-2 mb-0">Select Team</label>
                                  <select class="select form-control wide mt-1" value={team} onChange={(e) => {
                                    console.log(e.target.value);

                                    setTeam(e.target.value)
                                    showAllStudentsBasedOnTeam(e.target.value)
                                  }}>
                                    <option value="">Select</option>
                                    {options.map((option, index) => (
                                      <option value={option.value}>{option.label}</option>
                                    ))
                                    }
                                  </select>
                                </div>
                                <div class="">
                                  <button type="button" class="btn btn-primary mt-4" onClick={UpdateTeamDataAssessment}>
                                    <i class="fas fa-add me-3"></i>Assign Team
                                  </button>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="card">
                            <div className="card-body">
                              {/* Search and Items per page */}
                              <div className="d-flex align-items-center justify-content-between mb-4">
                                <div className="col-xl-6">
                                  <div className="input-group mb-2">
                                    <div className="input-group-text">
                                      <i className="fas fa-search"></i>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control wide"
                                      placeholder="Search by Name / Student ID / Mobile / E-Mail ID"
                                      value={searchTerm}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                </div>
                                <div className="col-xl-3">
                                  <select
                                    className="form-control wide"
                                    value={itemsPerPage}
                                    onChange={handleItemsPerPageChange}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <option value="5">5 - items per page</option>
                                    <option value="10">10 - items per page</option>
                                    <option value="15">15 - items per page</option>
                                    <option value="20">20 - items per page</option>
                                  </select>
                                </div>
                              </div>

                              {/* Students Table */}
                              <div className="table-responsive">
                                <table className="table table-responsive-md">
                                  <thead className="mx-auto text-center">
                                    <tr>
                                      <th>S.No</th>
                                      <th>Student Name</th>
                                      <th>Student ID</th>
                                      <th>Email ID</th>
                                      <th>Mobile Number</th>
                                      <th>Department</th>
                                    </tr>
                                  </thead>
                                  <tbody className="mx-auto text-center">
                                    {paginatedStudents.map((student, index) => (
                                      <tr key={index}>
                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                        <td>{student.name}</td>
                                        <td>{student.studentid}</td>
                                        <td>{student.emailid}</td>
                                        <td>{student.number}</td>
                                        <td>{student.department}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>

                              {/* Pagination and Entries Info */}
                              <div className="d-flex justify-content-between align-items-center mt-3">
                                <div className="text-dark">
                                  Showing <b className="text-primary">{paginatedStudents.length}</b> of{" "}
                                  <b className="text-primary">{totalItems}</b> entries
                                </div>
                                <div className="text-dark">
                                  <span className="me-4">
                                    Page <b className="text-primary">{currentPage}</b> of{" "}
                                    <b className="text-primary">{totalPages}</b>
                                  </span>
                                  <button
                                    className="btn btn-xs btn-primary"
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                  >
                                    <i className="fas fa-chevron-left"></i>
                                  </button>
                                  <button
                                    className="btn btn-xs btn-primary ms-2"
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages || totalPages === 0}
                                  >
                                    <i className="fas fa-chevron-right"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default BotSummary
