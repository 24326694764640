import React, { useState } from 'react';
import blogo from '../../imgs/FullLogo.png';
import { BaseRoutes } from '../../CommonConfig/BaseRoutes';
import Swal from 'sweetalert2';
import { apiURL } from '../../CommonConfig/BaseUrl';
import axios from 'axios';

// const apiUrl = process.env.REACT_APP_API_URL;
// console.log('API URL:', apiUrl);

function SignIn() {
    const [activeTab, setActiveTab] = useState('admin');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const credentials = {
        admin: { username: 'adminUser', password: 'admin123' },
        user: { username: 'studentUser', password: 'user123' },
        dummy: { username: 'studentUser', password: 'user123' },
    };

    const handleLogin = async (role) => {
        debugger
        if (role == "user") {

            var payload = {
                "username": username,
                "password": password,
            };

            try {
                const response = await axios.post(`${apiURL}/student_signin`, payload);
                if (response.data!=null && response.data.id != null) {
                    sessionStorage.setItem('authToken', 'dummyToken');
                    sessionStorage.setItem('role', role);
                    sessionStorage.setItem ('username', username);
                    sessionStorage.setItem ('id', response.data.id);
                    sessionStorage.setItem ('emailid', response.data.emailid); 
                    localStorage.setItem('authToken', 'dummyToken');
                    localStorage.setItem('role', role);
                    localStorage.setItem ('username', username);
                    localStorage.setItem ('id', response.data.id);
                    localStorage.setItem ('emailid', response.data.emailid);
                    const hubId = response.hub_id;
                    localStorage.setItem('hub_id', hubId || 1);
                    sessionStorage.setItem('hub_id', hubId || 1);
                    window.location.href = BaseRoutes.KnowledgeScreen;
                } else {
                    Swal.fire('Login failed', 'Check username & password');
                }

            } catch (error) {
                console.error('Error making POST request:', error);
                Swal.fire('Error making POST request', 'error');
            }
        }
        else{
            if (username === credentials[role].username && password === credentials[role].password) {
                sessionStorage.setItem('authToken', 'dummyToken');
                sessionStorage.setItem('role', role);
                sessionStorage.setItem('username', username);
                localStorage.setItem('authToken', 'dummyToken');
                localStorage.setItem('role', role);
                localStorage.setItem ('username', username);
                localStorage.setItem ('id', 0);

                if (role == "admin") {
                    window.location.href = BaseRoutes.Dashboard;
                } else {
                    window.location.href = BaseRoutes.KnowledgeScreen;
                }
            } else {
                Swal.fire('Login failed', 'Invalid username or password');
            }
        }
        
    };

    // const showAlert = (message, type) => {
    //     Swal.fire({
    //       title: message,
    //       icon: type,
    //       confirmButtonText: 'OK',
    //       confirmButtonColor: '#2EA87E',
    //     });
    //   };

    // const handleLogin = (role) => {
    //     localStorage.setItem('authToken', 'dummyToken');
    //     localStorage.setItem('role', role);

    //     window.location.href = BaseRoutes.Dashboard;
    // };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className="fix-wrapper">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6">
                        <div className="card mb-0 h-auto">
                            <div className="card-body">
                                <div className="text-center mb-3">
                                    <a href={BaseRoutes.Dashboard}>
                                        <img className="logo-auth" src={blogo} style={{ width: 200 }} alt="Logo" />
                                    </a>
                                </div>
                                <h4 className="text-center mb-4">Sign In</h4>
                                <div className="card-body">
                                    <div className="card-tabs">
                                        <ul className="nav nav-tabs d-inline-flex mb-4" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${activeTab === 'admin' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('admin')}
                                                >
                                                    Sign In as Admin
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${activeTab === 'user' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('user')}
                                                >
                                                    Sign In as User
                                                </button>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="tab-content">
                                        <div
                                            id="navpills2-11"
                                            className={`tab-pane ${activeTab === 'admin' ? 'active show' : ''}`}
                                            role="tabpanel"
                                        >
                                            <div className="row">
                                                <h4 className="text-center mb-4">Sign In as Admin</h4>
                                                <div className="form-group mb-4">
                                                    <label className="form-label" htmlFor="username">Username</label>
                                                    <input type="text" className="form-control" placeholder="Enter username" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                                </div>
                                                {/* <div className="form-group mb-4">
                                                    <label className="form-label" htmlFor="email">Email</label>
                                                    <input type="email" className="form-control" placeholder="hello@example.com" id="email" />
                                                </div> */}
                                                <div className="mb-sm-4 mb-3 position-relative">
                                                    <label className="form-label" htmlFor="password">Password</label>
                                                    <input
                                                        type={passwordVisible ? 'text' : 'password'}
                                                        id="password"
                                                        className="form-control"
                                                        placeholder="Enter password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    <span className="show-pass eye" onClick={togglePasswordVisibility}>
                                                        <i className={`fa ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'}`} />
                                                    </span>
                                                </div>
                                                <div className="text-center">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-block"
                                                        onClick={() => handleLogin('admin')}
                                                    >
                                                        Sign in as Admin
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="navpills2-21"
                                            className={`tab-pane ${activeTab === 'user' ? 'active show' : ''}`}
                                            role="tabpanel"
                                        >
                                            <div className="row">
                                                <h4 className="text-center mb-4">Sign In as User</h4>
                                                <div className="form-group mb-4">
                                                    <label className="form-label" htmlFor="username">Username</label>
                                                    <input type="text" className="form-control" placeholder="Enter username" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                                </div>
                                                {/* <div className="form-group mb-4">
                                                    <label className="form-label" htmlFor="email">Email</label>
                                                    <input type="email" className="form-control" placeholder="hello@example.com" id="email" />
                                                </div> */}
                                                <div className="mb-sm-4 mb-3 position-relative">
                                                    <label className="form-label" htmlFor="password">Password</label>
                                                    <input
                                                        type={passwordVisible ? 'text' : 'password'}
                                                        id="password"
                                                        className="form-control"
                                                        placeholder="Enter password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    <span className="show-pass eye" onClick={togglePasswordVisibility}>
                                                        <i className={`fa ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'}`} />
                                                    </span>
                                                </div>
                                                <div className="text-center">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-block"
                                                        onClick={() => handleLogin('user')}
                                                    >
                                                        Sign in as User
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="new-account mt-3">
                                    <p>Already have an account? <a className="text-primary" href={BaseRoutes.Dashboard}>Sign in</a></p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignIn;
