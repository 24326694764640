import React from 'react';
import { Navigate } from 'react-router-dom';
import { BaseRoutes } from '../CommonConfig/BaseRoutes';


const isAuthenticated = () => {
  return localStorage.getItem('authToken'); 
};

const PrivateRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to={BaseRoutes.SignIn} />;
};

export default PrivateRoute;
