import React, { useState, useEffect } from 'react'
import NavBar from '../../Layouts/NavBar'
import Header from '../../Layouts/Header'
import SideBar from '../../Layouts/SideBar'
import Footer from '../../Layouts/Footer';
import axios from 'axios';
import Swal from 'sweetalert2';
import { apiURL } from '../../CommonConfig/BaseUrl';


function InterviewBotConfig() {

  const [color, setColor] = useState('#ffffff');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [botname, setBotname] = useState("");
  const [fontsize, setFontsize] = useState(14);
  const [fontfamily, setFontfamily] = useState("Arial");
  const [welcomemsg, setWelcomemsg] = useState("Welcome");
  const [unknownmsg, setUnknownmsg] = useState("I don't understand");
  const [disclimermsg, setDisclimermsg] = useState("This is a general disclaimer.");
  const [options, setOptions] = useState([]);
  const [team, setTeam] = useState(null);
  const [assessmentHubId, setAssessmentHubId] = useState(0);
  const [loading, setLoading] = useState(false);

  const [students, setStudents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const handleColorChange = (e) => {
    setColor(e.target.value);
  };

  const handleHexInputChange = (e) => {
    const hex = e.target.value;
    if (/^#([0-9A-F]{3}){1,2}$/i.test(hex)) {
      setColor(hex);
    } else {
      setColor('#ffffff');
    }
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleSubmit = async () => {
    var Botname = botname;
    var Botcolor = color;
    var Fontsize = fontsize;
    var Fontfamily = fontfamily;
    var Welcomemsg = welcomemsg;
    var Unknownmsg = unknownmsg;
    var Disclimermsg = disclimermsg;

    if (!Botname || !Botcolor || !Fontsize || !Fontfamily || !Welcomemsg || !Unknownmsg || !Disclimermsg) {
      showAlert('Please fill in all fields before submitting', 'error');
      return;
    }

    var payload = {
      "bot_name": Botname,
      "bot_color": Botcolor,
      "font_size": Fontsize,
      "font_family": Fontfamily,
      "welcome_message": Welcomemsg,
      "unknown_response": Unknownmsg,
      "disclimer_message": Disclimermsg,
      "created_by": 0,
    };

    try {
      const response = await axios.post(`${apiURL}/create_interview`, payload);
      if (response.data.id !== null) {
        setAssessmentHubId(response.data.id)
        showAlert('Interview Hub configuration added successfully', 'success');
      } else {
        showAlert('Interview Hub configuration added failed', 'error');
      }

    } catch (error) {
      console.error('Error making POST request:', error);
      showAlert('Error making POST request', 'error');
    }
  };

  const showAlert = (message, type) => {
    Swal.fire({
      title: message,
      icon: type,
      confirmButtonText: 'OK',
      confirmButtonColor: '#2EA87E',
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiURL}/get_all_team`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });

      const result = await response.json();

      if (result.message === "Success") {
        const formattedOptions = result.data.map((team) => ({
          value: team.id,
          label: team.team_name,
        }));
        setOptions(formattedOptions);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const UpdateTeamData = async () => {
    if (team == null) {
      showAlert('Please select team', 'warning');
      return;
    }
    try {
      const response = await axios.put(`${apiURL}/update_assessment_team/${assessmentHubId}/${team}`, {
        headers: {
          'Accept': 'application/json'
        }
      });
      if (response.status === 200) {
        showAlert('Team Assigned successfully!', 'success');
      } else {
        showAlert('Team Assigned failed', 'error');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const upload = async () => {
    if (uploadedFiles.length === 0) {
      showAlert('Please select at least one file to upload', 'warning');
      return;
    }

    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append('file', file);
    });

    setLoading(true); // Start loading

    try {
      const response = await axios.post(`${apiURL}/generate_qa/${assessmentHubId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        if (response.data.file_url !== "") {
          showAlert('File(s) uploaded successfully!', 'success');
        } else {
          showAlert('File upload failed', 'error');
        }
      } else {
        showAlert('File upload failed', 'error');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      Swal.fire({
        title: 'Error uploading file',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#2EA87E',
      });
    } finally {
      setLoading(false); // Stop loading after upload completion or error
    }
  };

  const fetchStudents = async () => {
    try {
      const response = await axios.get(`${apiURL}/get_all_Students`);

      if (response.status === 200 && response.data && response.data.data) {
        const sortedData = response.data.data.sort((a, b) => a.id - b.id);
        setStudents(sortedData);
        // setCount(sortedData.length);
      } else {
        // toastr.error('Failed to load students');
      }
    } catch (error) {
      console.error('Error fetching students:', error);
      // toastr.error('An error occurred while fetching students');
    } finally {
    }
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const filteredStudents = students.filter(
    (student) =>
      student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.studentid.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.number.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.emailid.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalItems = filteredStudents.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const paginatedStudents = filteredStudents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHour = hours % 12 || 12;

    return `${day} / ${month} / ${year} - ${formattedHour}:${minutes} ${ampm}`;
  };

  return (
    <>
      <div id='main-wrapper'>
        <NavBar />
        <Header />
        <SideBar />
        <div class="content-body default-height">
          <div class="container-fluid">
            <div className="row d-flex justify-content-center">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-header">

                    <h4 class="card-title">Assessment Hub Configure</h4>

                  </div>
                  <div class="card-body">
                    <div class="card-tabs d-flex flex-wrap justify-content-between align-items-center mb-1">
                      <span>
                        <ul class="nav nav-tabs d-inline-flex" role="tablist">
                          <li class=" nav-item" role="presentation">
                            <a href="#navpills2-11" class="nav-link active" data-bs-toggle="tab" aria-expanded="false" aria-selected="true" role="tab">Hub Configure</a>
                          </li>
                          <li class="nav-item" role="presentation">
                            <a href="#navpills2-21" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Upload Files</a>
                          </li>
                          <li class="nav-item" role="presentation">
                            <a href="#navpills2-23" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Assign Team</a>
                          </li>
                        </ul>
                      </span>
                      {/* <span>
                        <button className='btn btn-primary'>Synchronize Hub</button>
                      </span> */}
                    </div>

                    <div class="tab-content">
                      <div id="navpills2-11" class="tab-pane active show" role="tabpanel">
                        <div class="card-body">
                          <div class="basic-form">
                            <form>
                              <div class="row">
                                <div class="mb-3 col-md-6">
                                  <label class="form-label">Hub Name</label>
                                  <input type="text" class="form-control" placeholder="Enter Hub Name" value={botname} onChange={(e) => setBotname(e.target.value)} />
                                </div>
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">Hub Color</label>
                                  <div className="input-group">
                                    <input type="color" className="form-control" value={color} onChange={handleColorChange} />
                                    <input type="text" className="form-control w-50" placeholder="#ffffff" value={color} onChange={handleHexInputChange} />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="mb-3 col-md-6">
                                  <label class="form-label">Font Size</label>
                                  <input type="number" class="form-control" placeholder="Font Size" value={fontsize} onChange={(e) => setFontsize(e.target.value)} />
                                </div>
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">Font Family</label>
                                  <select
                                    className="form-control"
                                    value={fontfamily}
                                    onChange={(e) => setFontfamily(e.target.value)}
                                  >
                                    <option value="Arial">Arial</option>
                                    <option value="Verdana">Verdana</option>
                                    <option value="Times New Roman">Times New Roman</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Courier New">Courier New</option>
                                    <option value="Tahoma">Tahoma</option>
                                    <option value="Trebuchet MS">Trebuchet MS</option>
                                  </select>
                                </div>
                              </div>
                              <div class="row">
                                <div class="mb-3 col-md-12">
                                  <label class="form-label">Hub Welcome Message</label>
                                  <select
                                    class="form-control"
                                    value={welcomemsg}
                                    onChange={(e) => setWelcomemsg(e.target.value)}
                                  >
                                    <option value="Welcome">Welcome</option>
                                    <option value="Hello">Hello</option>
                                    <option value="Greetings">Greetings</option>
                                  </select>
                                </div>
                              </div>
                              <div class="row">
                                <div class="mb-3 col-md-12">
                                  <label class="form-label">Hub Unknown Response</label>
                                  <select
                                    class="form-control"
                                    value={unknownmsg}
                                    onChange={(e) => setUnknownmsg(e.target.value)}
                                  >
                                    <option value="I don't understand">I don't understand</option>
                                    <option value="Can you rephrase?">Can you rephrase?</option>
                                    <option value="Sorry, I didn’t get that">Sorry, I didn’t get that</option>
                                  </select>
                                </div>
                              </div>
                              <div class="row">
                                <div class="mb-3 col-md-12">
                                  <label class="form-label">Hub Disclaimer Message</label>
                                  <select
                                    class="form-control"
                                    value={disclimermsg}
                                    onChange={(e) => setDisclimermsg(e.target.value)}
                                  >
                                    <option value="This is a general disclaimer.">This is a general disclaimer.</option>
                                    <option value="Please verify all information.">Please verify all information.</option>
                                    <option value="No guarantees are provided.">No guarantees are provided.</option>
                                  </select>
                                </div>
                              </div>
                              <div className='mt-2 text-center'>
                                <button type="button" class="btn btn-primary" onClick={handleSubmit}>Create Hub</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div id="navpills2-21" class="tab-pane" role="tabpanel">
                        <div className="card-body d-flex justify-content-center">
                          <div className="col-xl-6 col-xxl-6">
                            <div className="d-flex justify-content-center mb-2">
                              <h4 className="card-title">Upload your Documents</h4>
                            </div>
                            <div className="col-lg-12 px-5">
                              <input
                                className="form-control"
                                type="file"
                                id="fileUpload"
                                multiple
                                onChange={handleFileUpload}
                                style={{ display: 'none' }}
                              />
                              <label
                                htmlFor="fileUpload"
                                className="drag-drop-area bg-success-light d-flex flex-column justify-content-between"
                                style={{
                                  border: '0.2rem dashed #cccccc',
                                  borderRadius: '1vh',
                                  padding: '12vh',
                                  paddingBottom: '4vh',
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                  minHeight: '200px', // Ensures label is tall enough to hold content
                                }}
                              >
                                <div>
                                  <i className="fas fa-upload"></i>
                                  <p>Drag & Drop your file here or click to upload</p>
                                </div>
                                <div style={{ marginTop: '40px' }}>
                                  <h6>Instructions</h6>
                                  <ul style={{ paddingLeft: '1rem', textAlign: 'center' }}>
                                    <li>Uploaded files must not exceed 20 pages.</li>
                                    <li>Please ensure that the file size is less than 50MB.</li>
                                  </ul>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='mt-2 text-center'>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={upload}
                            disabled={loading} // Disable while loading
                          >
                            {loading ? 'Uploading...' : 'Upload'} {/* Display loader text */}
                          </button>
                        </div>

                        <div class="row">
                          <div className="d-flex justify-content-center">
                            <h4 className="card-title mb-3">Existing Files</h4>
                          </div>
                          <hr />
                          <div class="card col-xl mx-xl-4">
                            <div class="card-body text-center ai-icon text-primary">
                              <svg
                                id="pdf-icon"
                                className="my-1"
                                viewBox="0 0 24 24"
                                width="60"
                                height="60"
                                stroke="currentColor"
                                fill="none"
                              >
                                <path d="M6 2H16L20 6V22H4V2H6Z" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M16 2V10H20" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 12H16" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 16H12" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                              <h5 class="my-1">Indian Politics New.pdf</h5>
                              <a href="javascript:void(0);" class="btn btn-primary btn-xs my-1 px-3">
                                Download
                              </a>
                              <button type="button" class="btn btn-danger btn-xs my-1 mx-3" onclick="handleButtonClick()">
                                Delete
                              </button>
                            </div>
                          </div>
                          <div class="card col-xl mx-xl-4">
                            <div class="card-body text-center ai-icon text-primary">
                              <svg
                                id="pdf-icon"
                                className="my-1"
                                viewBox="0 0 24 24"
                                width="60"
                                height="60"
                                stroke="currentColor"
                                fill="none"
                              >
                                <path d="M6 2H16L20 6V22H4V2H6Z" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M16 2V10H20" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 12H16" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 16H12" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                              <h5 class="my-1">Economic Survey.pdf</h5>
                              <a href="javascript:void(0);" class="btn btn-primary btn-xs my-1 px-3">
                                Download
                              </a>
                              <button type="button" class="btn btn-danger btn-xs my-1 mx-3" onclick="handleButtonClick()">
                                Delete
                              </button>
                            </div>
                          </div>
                          <div class="card col-xl mx-xl-4">
                            <div class="card-body text-center ai-icon text-primary">
                              <svg
                                id="pdf-icon"
                                className="my-1"
                                viewBox="0 0 24 24"
                                width="60"
                                height="60"
                                stroke="currentColor"
                                fill="none"
                              >
                                <path d="M6 2H16L20 6V22H4V2H6Z" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M16 2V10H20" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 12H16" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 16H12" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                              <h5 class="my-1">Ethics.pdf</h5>
                              <a href="javascript:void(0);" class="btn btn-primary btn-xs my-1 px-3">
                                Download
                              </a>
                              <button type="button" class="btn btn-danger btn-xs my-1 mx-3" onclick="handleButtonClick()">
                                Delete
                              </button>
                            </div>
                          </div>
                          <div class="card col-xl mx-xl-4">
                            <div class="card-body text-center ai-icon text-primary">
                              <svg
                                id="pdf-icon"
                                className="my-1"
                                viewBox="0 0 24 24"
                                width="60"
                                height="60"
                                stroke="currentColor"
                                fill="none"
                              >
                                <path d="M6 2H16L20 6V22H4V2H6Z" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M16 2V10H20" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 12H16" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 16H12" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                              <h5 class="my-1">Current affairs New.pdf</h5>
                              <a href="javascript:void(0);" class="btn btn-primary btn-xs my-1 px-3">
                                Download
                              </a>
                              <button type="button" class="btn btn-danger btn-xs my-1 mx-3" onclick="handleButtonClick()">
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-4">
                          <div class="col-xl-6">
                            <div class="card">
                              <div class="card-header ">
                                <h4 class="card-title text-muted">Select Files For Assessment</h4>
                              </div>
                              <div class="card-body p-0">
                                <div id="DZ_W_Todo4" class="widget-media dlab-scroll p-4 height370">
                                  <ul class="timeline">
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="form-check custom-checkbox checkbox-success check-lg me-3">
                                          <input type="checkbox" class="form-check-input" id="customCheckBox1" required="" />
                                          <label class="form-check-label" for="customCheckBox1"></label>
                                        </div>
                                        <div class="media-body">
                                          <h5 class="mb-0">Indian Politics.pdf</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="form-check custom-checkbox checkbox-warning check-lg me-3">
                                          <input type="checkbox" class="form-check-input" checked id="customCheckBox2" required="" />
                                          <label class="form-check-label" for="customCheckBox2"></label>
                                        </div>
                                        <div class="media-body">
                                          <h5 class="mb-0">Economic Survey.pdf</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="form-check custom-checkbox checkbox-primary check-lg me-3">
                                          <input type="checkbox" class="form-check-input" checked id="customCheckBox3" required="" />
                                          <label class="form-check-label" for="customCheckBox3"></label>
                                        </div>
                                        <div class="media-body">
                                          <h5 class="mb-0">Current Affairs.pdf</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="form-check custom-checkbox checkbox-warning check-lg me-3">
                                          <input type="checkbox" class="form-check-input" checked id="customCheckBox6" required="" />
                                          <label class="form-check-label" for="customCheckBox6"></label>
                                        </div>
                                        <div class="media-body">
                                          <h5 class="mb-0">Ethics.pdf</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-6">
                            <div class="card">
                              <div class="card-header">
                                <h4 class="card-title text-muted">Knowledge for Assessment Hub</h4>
                              </div>
                              <div class="card-body p-0">
                                <div id="DZ_W_Todo4" class="widget-media dlab-scroll p-4 height370">
                                  <ul class="timeline">
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="media-body">
                                          <h5 class="mb-0">Current Affairs.pdf</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="media-body">
                                          <h5 class="mb-0">Economic Survey.pdf</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="media-body">
                                          <h5 class="mb-0">Ethics.pdf</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="navpills2-23" class="tab-pane" role="tabpanel">
                        {/* <div class="row page-titles"><ol class="breadcrumb"><h3 class="text-primary">Assessment Hub Name</h3></ol></div> */}
                        <hr />
                        <div className='card-body'>
                          <div class="d-flex flex-wrap align-items-center justify-content-between">

                            <div class="align-items-center d-flex">
                              <div class="me-4">
                                <label class="text-primary fw-bold ms-2 mb-0">Select Team</label>
                                <select class="select form-control wide mt-1" value={team} onChange={(e) => setTeam(e.target.value)}>
                                  <option value="">Select</option>
                                  {options.map((option, index) => (
                                    <option value={option.value}>{option.label}</option>
                                  ))
                                  }
                                </select>
                              </div>
                              <div class="">
                                <button type="button" class="btn btn-primary mt-4" onClick={UpdateTeamData}>
                                  <i class="fas fa-add me-3"></i>Assign Team
                                </button>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="card">
                          <div class="card-body">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                              <div className="col-xl-6">
                                <div className="input-group mb-2">
                                  <div className="input-group-text"><i className="fas fa-search"></i></div>
                                  <input
                                    type="text"
                                    className="form-control wide"
                                    placeholder="Search by Name / Student ID / Mobile / E-Mail ID"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                  />
                                </div>
                              </div>
                              <div className="col-xl-3">
                                <select
                                  className="form-control wide"
                                  value={itemsPerPage}
                                  onChange={handleItemsPerPageChange}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <option value="5">5 - items per page</option>
                                  <option value="10">10 - items per page</option>
                                  <option value="15">15 - items per page</option>
                                  <option value="20">20 - items per page</option>
                                </select>
                              </div>
                            </div>

                            <div className="table-responsive">
                              <table className="table table-responsive-md">
                                <thead className="mx-auto text-center">
                                  <tr>
                                    <th>S.No</th>
                                    <th>Student Name</th>
                                    <th>Student ID</th>
                                    <th>Email ID</th>
                                    <th>Password</th>
                                    <th>Mobile Number</th>
                                    <th>Department</th>
                                  </tr>
                                </thead>
                                <tbody className="mx-auto text-center">
                                  {paginatedStudents.length > 0 ? (
                                    paginatedStudents.map((student, index) => (
                                      <tr key={student.id}>
                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                        <td>{student.name}</td>
                                        <td>{student.studentid}</td>
                                        <td>{student.emailid}</td>
                                        <td>{student.password}</td>
                                        <td>{student.number}</td>
                                        <td>{student.department}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="7">No students available</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>

                            <div className="d-flex justify-content-between align-items-center mt-3">
                              <div className="text-dark">
                                Showing <b className="text-primary">{paginatedStudents.length}</b> of{" "}
                                <b className="text-primary">{students.length}</b> entries
                              </div>
                              <div className="text-dark">
                                <span className="me-4">
                                  Page <b className="text-primary">{currentPage}</b> of{" "}
                                  <b className="text-primary">{totalPages}</b>
                                </span>
                                <button
                                  className="btn btn-xs btn-primary"
                                  onClick={() => handlePageChange(currentPage - 1)}
                                  disabled={currentPage === 1 || totalPages === 0}
                                >
                                  <i className="fas fa-chevron-left"></i>
                                </button>
                                <button
                                  className="btn btn-xs btn-primary ms-2"
                                  onClick={() => handlePageChange(currentPage + 1)}
                                  disabled={currentPage === totalPages || totalPages === 0}
                                >
                                  <i className="fas fa-chevron-right"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default InterviewBotConfig