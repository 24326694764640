import React from 'react'
import Footer from '../../Layouts/Footer'
import NavBar from '../../Layouts/NavBar'
import Header from '../../Layouts/Header'
import SideBar from '../../Layouts/SideBar'

function HubInfo() {
    return (
        <div>
            <div id="main-wrapper">
                <NavBar />
                <Header />
                <SideBar />
                <div class="content-body default-height">
                    <div class="container-fluid">
                        <div className="row d-flex justify-content-center">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Training Summary</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="card-tabs d-flex justify-content-between align-items-center mb-3">
                                            <span>
                                                <ul class="nav nav-tabs d-inline-flex" role="tablist">
                                                    <li class=" nav-item" role="presentation">
                                                        <a href="#navpills2-11" class="nav-link active" data-bs-toggle="tab" aria-expanded="false" aria-selected="true" role="tab">In Progress</a>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <a href="#navpills2-21" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Completed</a>
                                                    </li>
                                                    {/* <li class="nav-item" role="presentation">
                                                        <a href="#navpills2-22" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Assign Team</a>
                                                    </li> */}
                                                </ul>
                                            </span>
                                            {/* <span>
                                                <button className='btn btn-primary'>Reset Hub</button>
                                            </span> */}
                                        </div>

                                        <div class="tab-content">
                                            <div id="navpills2-11" class="tab-pane active show" role="tabpanel">
                                            <div className='card-body'>
                                                    <div className='d-flex align-items-center mb-4'>
                                                        <div className='col-xl-4 col-md-12'>
                                                            <div class="input-group">
                                                                <div class="input-group-text"><i className='fas fa-search'></i></div>
                                                                <input type="text" class="form-control wide" placeholder="Search" />
                                                            </div>
                                                        </div>
                                                        <div className='col-xl mx-2 col-md-12'>
                                                            <input type="date" class="form-control wide" placeholder="Search" />
                                                        </div>
                                                        <div className='col-xl-4 col-md-12'>
                                                            <select className='form-control wide' id="itemsPerPage" style={{ cursor: 'pointer' }}>
                                                                <option value="5"> 05 - items per page</option>
                                                                <option value="10"> 10 - items per page</option>
                                                                <option value="15"> 15 - items per page</option>
                                                                <option value="20"> 20 - items per page</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-responsive-md">
                                                            <thead className='mx-auto text-center'>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Hub ID</th>
                                                                    <th>Hub Name</th>
                                                                    <th>Team</th>
                                                                    
                                                                    <th>Status</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='mx-auto text-center'>
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>22</td>
                                                                    <td>UPSC</td>
                                                                    <td>team</td>
                                                                    
                                                                    <td><span className="badge bg-danger">50%</span></td>
                                                                    <td>
                                                                        <button className="btn bg-info-light">
                                                                            <i className="fas fa-pen-to-square"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>2</td>
                                                                    <td>33</td>
                                                                    <td>UPSC</td>
                                                                    <td>Mains</td>
                                                                    
                                                                    <td><span className="badge bg-danger">60%</span></td>
                                                                    <td>
                                                                        <button className="btn bg-info-light">
                                                                            <i className="fas fa-pen-to-square"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>3</td>
                                                                    <td>44</td>
                                                                    <td>UPSC</td>
                                                                    <td>Prelims</td>
                                                                    
                                                                    <td><span className="badge bg-danger">80%</span></td>
                                                                    <td>
                                                                        <button className="btn bg-info-light">
                                                                            <i className="fas fa-pen-to-square"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>4</td>
                                                                    <td>55</td>
                                                                    <td>UPSC</td>
                                                                    <td>Prelims</td>
                                                                    
                                                                    <td><span className="badge bg-danger">40%</span></td>
                                                                    <td>
                                                                        <button className="btn bg-info-light">
                                                                            <i className="fas fa-pen-to-square"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>5</td>
                                                                    <td>66</td>
                                                                    <td>UPSC</td>
                                                                    <td>Prelims</td>
    
                                                                    <td><span className="badge bg-danger">70%</span></td>
                                                                    <td>
                                                                        <button className="btn bg-info-light">
                                                                            <i className="fas fa-pen-to-square"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="d-flex justify-content-between flex-wrap align-items-center mt-3">
                                                        <div className='text-dark'>
                                                            Showing <b className='text-secondary'>5</b> of <b className='text-secondary'>17</b> entries
                                                        </div>
                                                        <div className='text-dark'>
                                                            <span className="me-4">Page <b className='text-secondary'>1</b> of  <b className='text-secondary'>3</b></span>
                                                            <button className='btn btn-xs btn-info light'>
                                                                <i className='fas fa-chevron-left'></i></button>
                                                            <button className='btn btn-xs btn-info light ms-2'>
                                                                <i className='fas fa-chevron-right'></i></button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div id="navpills2-21" class="tab-pane" role="tabpanel">
                                            <div className='card-body'>
                                                    <div className='d-flex align-items-center mb-4'>
                                                        <div className='col-xl-4'>
                                                            <div class="input-group">
                                                                <div class="input-group-text"><i className='fas fa-search'></i></div>
                                                                <input type="text" class="form-control wide" placeholder="Search" />
                                                            </div>
                                                        </div>
                                                        <div className='col-xl mx-2'>
                                                            <input type="date" class="form-control wide" placeholder="Search" />
                                                        </div>
                                                        <div className='col-xl-4'>
                                                            <select className='form-control wide' id="itemsPerPage" style={{ cursor: 'pointer' }}>
                                                                <option value="5"> 05 - items per page</option>
                                                                <option value="10"> 10 - items per page</option>
                                                                <option value="15"> 15 - items per page</option>
                                                                <option value="20"> 20 - items per page</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-responsive-md">
                                                            <thead className='mx-auto text-center'>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Hub ID</th>
                                                                    <th>Hub Name</th>
                                                                    <th>Team</th>
                                                            
                                                                    <th>Status</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='mx-auto text-center'>
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>22</td>
                                                                    <td>UPSC</td>
                                                                    <td>team</td>
                                                                    
                                                                    <td><span className="badge bg-success">Done</span></td>
                                                                    <td>
                                                                        <button className="btn bg-info-light">
                                                                            <i className="fas fa-pen-to-square"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>2</td>
                                                                    <td>33</td>
                                                                    <td>UPSC</td>
                                                                    <td>Mains</td>
                                                                    
                                                                    <td><span className="badge bg-success">Done</span></td>
                                                                    <td>
                                                                        <button className="btn bg-info-light">
                                                                            <i className="fas fa-pen-to-square"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>3</td>
                                                                    <td>44</td>
                                                                    <td>UPSC</td>
                                                                    <td>Prelims</td>
                                                                    
                                                                    <td><span className="badge bg-success">Done</span></td>
                                                                    <td>
                                                                        <button className="btn bg-info-light">
                                                                            <i className="fas fa-pen-to-square"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>4</td>
                                                                    <td>55</td>
                                                                    <td>UPSC</td>
                                                                    <td>Prelims</td>
                                                                    
                                                                    <td><span className="badge bg-success">Done</span></td>
                                                                    <td>
                                                                        <button className="btn bg-info-light">
                                                                            <i className="fas fa-pen-to-square"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>5</td>
                                                                    <td>66</td>
                                                                    <td>UPSC</td>
                                                                    <td>Prelims</td>
    
                                                                    <td><span className="badge bg-success">Done</span></td>
                                                                    <td>
                                                                        <button className="btn bg-info-light">
                                                                            <i className="fas fa-pen-to-square"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="d-flex justify-content-between flex-wrap align-items-center mt-3">
                                                        <div className='text-dark'>
                                                            Showing <b className='text-secondary'>5</b> of <b className='text-secondary'>17</b> entries
                                                        </div>
                                                        <div className='text-dark'>
                                                            <span className="me-4">Page <b className='text-secondary'>1</b> of  <b className='text-secondary'>3</b></span>
                                                            <button className='btn btn-xs btn-info light'>
                                                                <i className='fas fa-chevron-left'></i></button>
                                                            <button className='btn btn-xs btn-info light ms-2'>
                                                                <i className='fas fa-chevron-right'></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="navpills2-22" class="tab-pane" role="tabpanel">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HubInfo