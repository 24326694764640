import React from 'react'
import vlogo from '../../imgs/verandaLog.png';
import CountUp from 'react-countup';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import NavBar from '../../Layouts/NavBar';
import Header from '../../Layouts/Header';


function InterviewSummary() {

    const donutData = {
        labels: ['Passed', 'Failed'],
        datasets: [
            {
                label: '  In Assessment',
                data: [24, 15], // Static data example
                backgroundColor: ['#28a745', '#dc3545'],
                hoverOffset: 4,
            },
        ],
    };

    const donutOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
    };
    return (
        <div id='main-wrapper'>
                <NavBar />
                <Header />
        
            <div className="">
                <div className='card-header bg-primary py-3 d-flex justify-content-between'>
                    <img src={vlogo} style={{ width: '5%' }} />
                    <h3 className='text-white m-0 p-0'>Assessment Hub</h3>
                </div>

            </div>
            <div className='row mt-3 justify-content-center '>
                <div className='col-6 me-3  card'>
                    <div className="text-center my-3">
                        <i className='fas fa-user-circle text-info fs-1 my-4'></i>
                        <p className="m-0 fs-4 fw-bold">Rithick R</p>
                        <p className="m-0 text-dark fs-6">Student</p>
                    </div>
                    <div className="border-green rounded p-3">
                        <div className="d-flex align-items-center justify-content-between mx-4">
                            <p className="m-0 p-0 text-dark fs-5">ID</p>
                            <p className="m-0 p-0 fs-5 fw-bold"># 04</p>
                        </div>
                        <hr />
                        <div className="d-flex align-items-center justify-content-between mx-4">
                            <p className="m-0 p-0 text-dark fs-5">Department</p>
                            <p className="m-0 p-0 fs-5 fw-bold">UPSC</p>
                        </div>
                        <hr />
                        <div className="d-flex align-items-center justify-content-between mx-4">
                            <p className="m-0 p-0 text-dark fs-5">Teacher</p>
                            <p className="m-0 p-0 fs-5 fw-bold">Nishanth</p>
                        </div>
                        <hr />
                        <div className="d-flex align-items-center justify-content-between mx-4">
                            <p className="m-0 p-0 text-dark fs-5">Joined Date</p>
                            <p className="m-0 p-0 fs-5 fw-bold">04-10-2024</p>
                        </div>
                        <hr />
                        <div className="d-flex align-items-center justify-content-between mx-4">
                            <p className="m-0 p-0 text-dark fs-5">Total Hours</p>
                            <p className="m-0 p-0 fs-5 fw-bold"><CountUp end={182} duration={4} /></p>
                        </div>
                        <hr />
                        {/* <div className="d-flex align-items-center justify-content-between mx-4">
                                                <p className="m-0 p-0 text-dark fs-5">Attended Hours</p>
                                                <p className="m-0 p-0 fs-5 fw-bold"><CountUp end={150} duration={4} /></p>
                                            </div>
                                            <hr />
                                            <div className="d-flex align-items-center justify-content-between mx-4">
                                                <p className="m-0 p-0 text-dark fs-5">Missed Hours</p>
                                                <p className="m-0 p-0 fs-5 fw-bold"><CountUp end={32} duration={4} /></p>
                                            </div> */}

                        {/* Donut Chart */}
                        <h5 className='text-center mt-3'>Assessment Results</h5>
                        <div style={{ height: '250px' }}>
                            <Doughnut data={donutData} options={donutOptions} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default InterviewSummary