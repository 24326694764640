import React from 'react'
import NavBar from '../../Layouts/NavBar'
import Header from '../../Layouts/Header'
import SideBar from '../../Layouts/SideBar'
import Footer from '../../Layouts/Footer'
import { useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';
import { apiURL } from '../../CommonConfig/BaseUrl';

function AssignTeam() {

  const [team, setTeam] = useState(null);
  const [hub, setHub] = useState(null);
  const [assessHub, setAssessHub] = useState(null);
  const [options, setOptions] = useState([]);
  const [botNames, setBotNames] = useState([]);
  const [knowledges, setKnowledges] = useState(null);
  const [assessments, setAssessments] = useState([]);
  const [studentBasedOnTeam, setStudentBasedOnTeam] = useState([]);
  const [hubDetails, setHubDetails] = useState(null);

  useEffect(() => {
    fetchData();
    fetchBotName();
    getData();
  }, []);

  const showAlert = (message, type) => {
    Swal.fire({
      title: message,
      icon: type,
      confirmButtonText: 'OK',
      confirmButtonColor: '#2EA87E',
    });
  };

  const handleTeamChange = (selectedTeamId) => {
    setTeam(selectedTeamId);
    console.log("Selected Team ID:", selectedTeamId);
    showAllStudentsBasedOnTeam(selectedTeamId); // Call your function with team ID
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiURL}/get_all_team`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      const result = await response.json();

      if (result.message === "Success") {
        const formattedOptions = result.data.map((team) => ({
          id: team.id, // Store team ID
          label: team.Name, // Display team name
        }));
        setOptions(formattedOptions);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const showAllStudentsBasedOnTeam = async (teamId) => {
    console.log(teamId);
    try {
      const response = await axios.get(`${apiURL}/get_team_by_id/${teamId}`);

      if (response.status === 200 && response.data && response.data.data) {
        const studentsListBasedOnTeam = response.data.data.students
        console.log(studentsListBasedOnTeam);
        setStudentBasedOnTeam(studentsListBasedOnTeam)
      } else {
        console.warn('Failed to load students');
      }
    } catch (error) {
      console.error('Error fetching students:', error);
    } finally {
    }
  };

  const UpdateTeamDataAssess = async () => {
    if (team == null) {
      showAlert('Please select team', 'warning');
      return;
    }
    try {
      const response = await axios.put(`${apiURL}/update_assessment_team/${assessHub}/${team}`, {
        headers: {
          'Accept': 'application/json'
        }
      });
      if (response.status === 200) {
        showAlert('Team Assigned successfully!', 'success');
      } else {
        showAlert('Team Assigned failed', 'error');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchBotName = async () => {
    try {
      const response = await axios.get(`${apiURL}/get_all_knowledge`);
      const data = response.data.data;
      setBotNames(data);
      console.log(response.data.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDatas = async (hub_id) => {
    axios.get(`${apiURL}/get_knowledge_by_id?id=${hub_id}`)
      .then((response) => {
        if (response.data && response.data.data) {
          setKnowledges(response.data.data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const getData = async () => {
    axios.get(`${apiURL}/get_all_interview`)
      .then((response) => {
        if (response.data && response.data.data) {
          setAssessments(response.data.data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }

  const UpdateTeamData = () => {
    console.log("values", hub, team);

    if (!hub || !team) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please select both Hub and Team.",
      });
      return;
    }

    console.log(`Assigning team "${team}" to hub "${hub}"`);

    axios
      .put(`${apiURL}/update_knowledge_team/${hub}/${team}`)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Team successfully assigned to Hub!",
        });
      })
      .catch((error) => {
        console.error("Error assigning team to hub:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response?.data?.message || "Failed to assign team to hub. Please try again.",
        });
      });
  };

  const handleHubChange = async (id) => {
    if (!id) {
      setHubDetails(null);
      return;
    }

    try {
      const response = await axios.get(`${apiURL}/get_Interview_by_id/${id}`);
      setHubDetails(response.data.data);
    } catch (error) {
      console.error("Error fetching hub details:", error);
      setHubDetails(null);
    }
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to page 1 when searching
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to page 1 when changing items per page
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const filteredStudents = studentBasedOnTeam.filter(
    (student) =>
      student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.studentid.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.number.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.emailid.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalItems = filteredStudents.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const paginatedStudents = filteredStudents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div>
      <div id="main-wrapper">
        <NavBar />
        <Header />
        <SideBar />
        <div class="content-body default-height">
          <div class="container-fluid">
            <div className="row d-flex justify-content-center">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Assign Batch</h4>
                  </div>
                  <div class="card-body">
                    <div class="card-tabs d-flex justify-content-between align-items-center mb-3">
                      <span>
                        <ul class="nav nav-tabs d-inline-flex" role="tablist">
                          <li class=" nav-item" role="presentation">
                            <a href="#navpills2-11" class="nav-link active" data-bs-toggle="tab" aria-expanded="false" aria-selected="true" role="tab">Assign Knowledge</a>
                          </li>
                          <li class="nav-item" role="presentation">
                            <a href="#navpills2-21" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Assign Assessment</a>
                          </li>
                        </ul>
                      </span>
                    </div>

                    <div class="tab-content">
                      <div id="navpills2-11" class="tab-pane active show" role="tabpanel">
                        <hr />
                        <div class="align-items-center d-flex ">
                          <div className="me-4">
                            <label className="text-primary fw-bold ms-2 mb-0">Select Hub</label>
                            <select
                              className="select form-control wide mt-1"
                              onChange={(e) => {
                                setHub(e.target.value);
                                fetchDatas(e.target.value);
                              }}
                            >
                              <option value="">Select</option>
                              {botNames?.map((name, index) => (
                                <option key={index} value={name.id}>
                                  {name.bot_name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div class="align-items-center d-flex">
                            <div className="me-4">
                              <label className="text-primary fw-bold ms-2 mb-0">Select Batch</label>
                              <select
                                className="select form-control wide mt-1"
                                value={team}
                                onChange={(e) => handleTeamChange(e.target.value)}
                              >
                                <option value="">Select</option>
                                {options.map((option, index) => (
                                  <option key={index} value={option.id}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div class="">
                            <button type="button" class="btn btn-primary mt-4" onClick={UpdateTeamData}>
                              Assign Batch to Hub
                            </button>
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className="mt-3 col-xl" style={{ maxHeight: '40vh' }}>
                            <div
                              className="widget-stat card"
                              id="your-card-id"
                            // data-bs-toggle="modal"
                            // data-bs-target=".bd-example-modal-lg10"
                            >
                              <div className="card-body">
                                <h4 className="mb-5">Selected Hub Info</h4>
                                <hr />
                                <div className="media ai-icon">
                                  <span className="me-3 bgl-primary text-primary">
                                    <svg
                                      id="icon-bot"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-bot"
                                    >
                                      <rect x="3" y="8" width="18" height="12" rx="2" ry="2"></rect>
                                      <path d="M12 2v4"></path>
                                      <circle cx="8" cy="14" r="1"></circle>
                                      <circle cx="16" cy="14" r="1"></circle>
                                      <path d="M20 16h2M2 16h2"></path>
                                    </svg>
                                  </span>
                                  {knowledges ? (
                                    <div className="media-body align-items-center">
                                      <h5 className="text-uppercase">{knowledges.bot_name}</h5>
                                      <p className="m-0">User Count / Batch Count</p>
                                      <>
                                        <h4 className="mb-0 mx-4">{knowledges.team || 30}</h4>
                                        <span className="badge badge-primary ms-4">{knowledges.batch_count || 5}</span>
                                      </>
                                    </div>
                                  ) : (
                                    <p>Select a Hub / No data for Hub</p>
                                  )}
                                </div>
                                <hr />
                              </div>
                            </div>
                          </div>

                          <div className="card mt-3 col-xl-8">
                            <div className="card-body">
                              <h4 className="mb-3">Selected Batch Students</h4>

                              {/* Search and Items per page */}
                              <div className="d-flex align-items-center justify-content-between mb-4">
                                <div className="col-xl-6">
                                  <div className="input-group mb-2">
                                    <div className="input-group-text">
                                      <i className="fas fa-search"></i>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control wide"
                                      placeholder="Search by Name / Student ID / Mobile / E-Mail ID"
                                      value={searchTerm}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                </div>
                                <div className="col-xl-3">
                                  <select
                                    className="form-control wide"
                                    value={itemsPerPage}
                                    onChange={handleItemsPerPageChange}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <option value="5">5 - items per page</option>
                                    <option value="10">10 - items per page</option>
                                    <option value="15">15 - items per page</option>
                                    <option value="20">20 - items per page</option>
                                  </select>
                                </div>
                              </div>

                              {/* Students Table */}
                              <div className="table-responsive">
                                <table className="table table-responsive-md">
                                  <thead className="mx-auto text-center">
                                    <tr>
                                      <th>S.No</th>
                                      <th>Student Name</th>
                                      <th>Student ID</th>
                                      <th>Email ID</th>
                                      <th>Mobile Number</th>
                                      <th>Department</th>
                                    </tr>
                                  </thead>
                                  <tbody className="mx-auto text-center">
                                    {paginatedStudents.map((student, index) => (
                                      <tr key={index}>
                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                        <td>{student.name}</td>
                                        <td>{student.studentid}</td>
                                        <td>{student.emailid}</td>
                                        <td>{student.number}</td>
                                        <td>{student.department}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>

                              {/* Pagination and Entries Info */}
                              <div className="d-flex justify-content-between align-items-center mt-3">
                                <div className="text-dark">
                                  Showing <b className="text-primary">{paginatedStudents.length}</b> of{" "}
                                  <b className="text-primary">{totalItems}</b> entries
                                </div>
                                <div className="text-dark">
                                  <span className="me-4">
                                    Page <b className="text-primary">{currentPage}</b> of{" "}
                                    <b className="text-primary">{totalPages}</b>
                                  </span>
                                  <button
                                    className="btn btn-xs btn-primary"
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                  >
                                    <i className="fas fa-chevron-left"></i>
                                  </button>
                                  <button
                                    className="btn btn-xs btn-primary ms-2"
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages || totalPages === 0}
                                  >
                                    <i className="fas fa-chevron-right"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="navpills2-21" class="tab-pane" role="tabpanel">
                        <hr />
                        <div class="align-items-center d-flex ">
                          <div className="me-4">
                            <label className="text-primary fw-bold ms-2 mb-0">Select Hub</label>
                            <select
                              className="select form-control wide mt-1"
                              onChange={(e) => {
                                setAssessHub(e.target.value);
                                handleHubChange(e.target.value);
                              }}
                            >
                              <option value="">Select</option>
                              {assessments?.map((name, index) => (
                                <option key={index} value={name.id}>
                                  {name.bot_name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div class="align-items-center d-flex">
                            <div className="me-4">
                              <label className="text-primary fw-bold ms-2 mb-0">Select Batch</label>
                              <select
                                className="select form-control wide mt-1"
                                value={team}
                                onChange={(e) => handleTeamChange(e.target.value)}
                              >
                                <option value="">Select</option>
                                {options.map((option, index) => (
                                  <option key={index} value={option.id}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div class="">
                            <button type="button" class="btn btn-primary mt-4" onClick={UpdateTeamDataAssess}>
                              Assign Batch to Hub
                            </button>
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className="mt-3 col-xl" style={{ maxHeight: '40vh' }}>
                            <div className="widget-stat card" id="your-card-id">
                              <div className="card-body">
                                <h4 className="mb-5">Selected Hub Info</h4>
                                <hr />
                                <div className="media ai-icon">
                                  <span className="me-3 bgl-primary text-primary">
                                    <svg
                                      id="icon-bot"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-bot"
                                    >
                                      <rect x="3" y="8" width="18" height="12" rx="2" ry="2"></rect>
                                      <path d="M12 2v4"></path>
                                      <circle cx="8" cy="14" r="1"></circle>
                                      <circle cx="16" cy="14" r="1"></circle>
                                      <path d="M20 16h2M2 16h2"></path>
                                    </svg>
                                  </span>
                                  {hubDetails ? (
                                    <div className="media-body align-items-center">
                                      <h5 className="text-uppercase">{hubDetails.bot_name}</h5>
                                      <p className="m-0">User Count / Batch Count</p>
                                      <>
                                        <h4 className="mb-0 mx-4">{hubDetails.team || 30}</h4>
                                        <span className="badge badge-primary ms-4">{hubDetails.batch_count || 5}</span>
                                      </>
                                    </div>
                                  ) : (
                                    <p>Select a Hub / No data for Hub</p>
                                  )}
                                </div>
                                <hr />
                              </div>
                            </div>
                          </div>

                          <div className="card mt-3 col-xl-8">
                            <div className="card-body">
                              <h4 className="mb-3">Selected Batch Students</h4>

                              {/* Search and Items per page */}
                              <div className="d-flex align-items-center justify-content-between mb-4">
                                <div className="col-xl-6">
                                  <div className="input-group mb-2">
                                    <div className="input-group-text">
                                      <i className="fas fa-search"></i>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control wide"
                                      placeholder="Search by Name / Student ID / Mobile / E-Mail ID"
                                      value={searchTerm}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                </div>
                                <div className="col-xl-3">
                                  <select
                                    className="form-control wide"
                                    value={itemsPerPage}
                                    onChange={handleItemsPerPageChange}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <option value="5">5 - items per page</option>
                                    <option value="10">10 - items per page</option>
                                    <option value="15">15 - items per page</option>
                                    <option value="20">20 - items per page</option>
                                  </select>
                                </div>
                              </div>

                              {/* Students Table */}
                              <div className="table-responsive">
                                <table className="table table-responsive-md">
                                  <thead className="mx-auto text-center">
                                    <tr>
                                      <th>S.No</th>
                                      <th>Student Name</th>
                                      <th>Student ID</th>
                                      <th>Email ID</th>
                                      <th>Mobile Number</th>
                                      <th>Department</th>
                                    </tr>
                                  </thead>
                                  <tbody className="mx-auto text-center">
                                    {paginatedStudents.map((student, index) => (
                                      <tr key={index}>
                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                        <td>{student.name}</td>
                                        <td>{student.studentid}</td>
                                        <td>{student.emailid}</td>
                                        <td>{student.number}</td>
                                        <td>{student.department}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>

                              {/* Pagination and Entries Info */}
                              <div className="d-flex justify-content-between align-items-center mt-3">
                                <div className="text-dark">
                                  Showing <b className="text-primary">{paginatedStudents.length}</b> of{" "}
                                  <b className="text-primary">{totalItems}</b> entries
                                </div>
                                <div className="text-dark">
                                  <span className="me-4">
                                    Page <b className="text-primary">{currentPage}</b> of{" "}
                                    <b className="text-primary">{totalPages}</b>
                                  </span>
                                  <button
                                    className="btn btn-xs btn-primary"
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                  >
                                    <i className="fas fa-chevron-left"></i>
                                  </button>
                                  <button
                                    className="btn btn-xs btn-primary ms-2"
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages || totalPages === 0}
                                  >
                                    <i className="fas fa-chevron-right"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AssignTeam