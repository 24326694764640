import React from 'react';
// import vlogo from '../imgs/veranda-logo-1200-x-900-01.png';
import blogo from '../imgs/FullLogo.png';
import { useNavigate } from 'react-router-dom';

function NavBar() {

  return (
    <div class="nav-header">
      <a href="/Dashboard" class="brand-logo">
        <img src={blogo} style={{width:'100%'}}></img>
      </a>
      <div class="nav-control">
        <div class="hamburger">
          <span class="line"></span><span class="line"></span><span class="line"></span>
        </div>
      </div>
    </div>
  )
}

export default NavBar