import React, { useEffect, useState } from 'react'
import SideBar from '../../Layouts/SideBar'
import NavBar from '../../Layouts/NavBar'
import Header from '../../Layouts/Header'
import Footer from '../../Layouts/Footer'
import { apiURL } from '../../CommonConfig/BaseUrl';
import axios from 'axios';

function DocSummary() {


    const [knowledges, setKnowledges] = useState([]);
    const [assessments, setAssessments] = useState([]);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        fetchKnowledgeData();
        fetchAssessmentData();
    }, []);

    const fetchKnowledgeData = async () => {
        const response = await fetch('https://api.syncviz.ai/vbot_getall_files');
        const data = await response.json();
        setKnowledges(data.db_vbot_knowledge);
        console.log(data, knowledges, "KnowledgeData");
    };

    const handleDeleteKnowledge = async (fileId, file_location) => {
        console.log(fileId, "file id");

        const confirmDelete = window.confirm(`Are you sure you want to delete the file with ID: ${fileId}?`);
        if (!confirmDelete) return;

        try {
            const response = await axios.delete(`https://api.syncviz.ai/deleteuplodfiles/?file_id=${fileId}&file_location=${file_location}`);

            if (response.status === 200) {
                alert('File deleted successfully.');
                setKnowledges((prevData) => prevData.filter(file => file.file_id !== fileId));
            } else {
                alert('Failed to delete the file.');
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            alert('An error occurred while deleting the file. Please try again.');
        }
    };

    const handleDownloadKnowledge = async (fileId, fileName) => {
        try {
            // Send a GET request to the download API
            const response = await axios.get(`https://api.syncviz.ai/Filedownload/${fileId}`, {
                responseType: 'blob',
            });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${fileName}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
            alert('An error occurred while downloading the file. Please try again.');
        }
    };

    const fetchAssessmentData = async () => {
        const response = await fetch('https://api.syncviz.ai/vbot_getall_files');
        const data = await response.json();
        setAssessments(data.db_vbot_knowledge);
        console.log(data, assessments, "AssessmentData");
    };

    const handleDeleteAssessment = async (fileId, file_location) => {
        console.log(fileId, "file id");

        const confirmDelete = window.confirm(`Are you sure you want to delete the file with ID: ${fileId}?`);
        if (!confirmDelete) return;

        try {
            const response = await axios.delete(`https://api.syncviz.ai/deleteuplodfiles/?file_id=${fileId}&file_location=${file_location}`);

            if (response.status === 200) {
                alert('File deleted successfully.');
                setAssessments((prevData) => prevData.filter(file => file.file_id !== fileId));
            } else {
                alert('Failed to delete the file.');
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            alert('An error occurred while deleting the file. Please try again.');
        }
    };

    const handleDownloadAssessment = async (fileId, fileName) => {
        try {
            // Send a GET request to the download API
            const response = await axios.get(`https://api.syncviz.ai/Filedownload/${fileId}`, {
                responseType: 'blob',
            });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${fileName}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
            alert('An error occurred while downloading the file. Please try again.');
        }
    };

    return (
        <div>
            <div id="main-wrapper">
                <NavBar />
                <Header />
                <SideBar />
                <div class="content-body default-height">
                    <div class="container-fluid">
                        <div className="row d-flex justify-content-center">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Document Summary</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="card-tabs">
                                            <ul class="nav nav-tabs d-inline-flex mb-4" role="tablist">
                                                <li class=" nav-item" role="presentation">
                                                    <a href="#navpills2-11" class="nav-link active" data-bs-toggle="tab" aria-expanded="false" aria-selected="true" role="tab">Knowledge Documents</a>
                                                </li>
                                                <li class="nav-item" role="presentation">

                                                    <a href="#navpills2-21" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Assessment Documents</a>

                                                </li>
                                            </ul>
                                        </div>

                                        <div class="tab-content">
                                            <div id="navpills2-11" class="tab-pane active show" role="tabpanel">
                                                <div className="row">
                                                    <div className="d-flex justify-content-center">
                                                        <h4 className="card-title mb-3">Existing Files</h4>
                                                    </div>
                                                    <hr />
                                                    {/* Display all cards, but toggle visibility of additional cards */}
                                                    {knowledges.map((file, index) => (
                                                        <div
                                                            className={`col-xl-3 col-md-4 col-sm-6 mb-4 ${index >= 8 && !showMore ? "d-none" : ""
                                                                }`}
                                                            key={file.file_id}
                                                        >
                                                            <div className="card">
                                                                <div className="card-body text-center ai-icon text-primary">
                                                                    <svg
                                                                        id="pdf-icon"
                                                                        className="my-1"
                                                                        viewBox="0 0 24 24"
                                                                        width="60"
                                                                        height="60"
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M6 2H16L20 6V22H4V2H6Z"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                        <path
                                                                            d="M16 2V10H20"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                        <path
                                                                            d="M8 12H16"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                        <path
                                                                            d="M8 16H12"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                    </svg>
                                                                    <h5
                                                                        className="my-1"
                                                                        title={file.file_name} // Full name as tooltip
                                                                    >
                                                                        {file.file_name.length > 20
                                                                            ? `${file.file_name.substring(0, 10)}...`
                                                                            : file.file_name}
                                                                    </h5>
                                                                    <a
                                                                        href="javascript:void(0);"
                                                                        className="btn btn-primary btn-xs my-1 px-3"
                                                                        onClick={() => handleDownloadKnowledge(file.file_id, file.file_name)} // Download the file
                                                                    >
                                                                        Download
                                                                    </a>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger btn-xs my-1 mx-3"
                                                                        onClick={() =>
                                                                            handleDeleteKnowledge(file.file_id, file.file_location)
                                                                        } // Delete the file
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {/* Toggle Button */}
                                                    {knowledges.length > 8 && (
                                                        <div className="col-12 text-center mt-4">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => setShowMore(!showMore)}
                                                            >
                                                                {showMore ? "Minimize" : "Show More"}
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div id="navpills2-21" class="tab-pane" role="tabpanel">
                                                <div className="row">
                                                    <div className="d-flex justify-content-center">
                                                        <h4 className="card-title mb-3">Existing Files</h4>
                                                    </div>
                                                    <hr />
                                                    {/* Display all cards, but toggle visibility of additional cards */}
                                                    {assessments.map((file, index) => (
                                                        <div
                                                            className={`col-xl-3 col-md-4 col-sm-6 mb-4 ${index >= 8 && !showMore ? "d-none" : ""
                                                                }`}
                                                            key={file.file_id}
                                                        >
                                                            <div className="card">
                                                                <div className="card-body text-center ai-icon text-primary">
                                                                    <svg
                                                                        id="pdf-icon"
                                                                        className="my-1"
                                                                        viewBox="0 0 24 24"
                                                                        width="60"
                                                                        height="60"
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M6 2H16L20 6V22H4V2H6Z"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                        <path
                                                                            d="M16 2V10H20"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                        <path
                                                                            d="M8 12H16"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                        <path
                                                                            d="M8 16H12"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                    </svg>
                                                                    <h5
                                                                        className="my-1"
                                                                        title={file.file_name} // Full name as tooltip
                                                                    >
                                                                        {file.file_name.length > 20
                                                                            ? `${file.file_name.substring(0, 10)}...`
                                                                            : file.file_name}
                                                                    </h5>
                                                                    <a
                                                                        href="javascript:void(0);"
                                                                        className="btn btn-primary btn-xs my-1 px-3"
                                                                        onClick={() => handleDownloadAssessment(file.file_id, file.file_name)} // Download the file
                                                                    >
                                                                        Download
                                                                    </a>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger btn-xs my-1 mx-3"
                                                                        onClick={() =>
                                                                            handleDeleteAssessment(file.file_id, file.file_location)
                                                                        } // Delete the file
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {/* Toggle Button */}
                                                    {assessments.length > 8 && (
                                                        <div className="col-12 text-center mt-4">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => setShowMore(!showMore)}
                                                            >
                                                                {showMore ? "Minimize" : "Show More"}
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default DocSummary